import {useState, useRef, useEffect, useCallback, useMemo /*, useLayoutEffect*/} from 'react';
import debounce from "lodash.debounce";
// import {mousePos} from "../app/dashboard/dashboard";

/**
 * https://github.com/mjsarfatti/use-mouse-leave/blob/master/src/index.tsx
 * https://gist.github.com/gragland/a32d08580b7e0604ff02cb069826ca2f
 */
export default function useHover() {
    const [hovered, setHovered] = useState(false);

    // Wrap in useCallback so we can use in dependencies below
    const handleMouseOver = useCallback(() => {
        setHovered(true)
        // console.log("mouse in")
    }, []);
    const handleMouseOut = useCallback(() => {
        setHovered(false)
        // console.log("mouse out")
    }, []);

    // Keep track of the last node passed to callbackRef
    // so we can remove its event listeners.
    const ref = useRef<HTMLDivElement>();

    // Use a callback ref instead of useEffect so that event listeners
    // get changed in the case that the returned ref gets added to
    // a different element later. With useEffect, changes to ref.current
    // wouldn't cause a rerender and thus the effect would run again.
    const callbackRef = useCallback(
        (node: HTMLDivElement) => {
            if (ref.current) {
                ref.current.removeEventListener("mouseenter", handleMouseOver);
                ref.current.removeEventListener("mouseleave", handleMouseOut);
            }

            ref.current = node;

            if (ref.current) {
                ref.current.addEventListener("mouseenter", handleMouseOver);
                ref.current.addEventListener("mouseleave", handleMouseOut);
            }
        },
        [handleMouseOver, handleMouseOut]
    );

    // const mouseMoveHandler = useMemo(() => debounce((e: MouseEvent) => {
    //     if (!ref.current) return;
    //     const rect = ref.current.getBoundingClientRect();
    //     if (e.clientX < rect.left || e.clientX > rect.right || e.clientY < rect.top || e.clientY > rect.bottom) {
    //         handleMouseOut();
    //     } else {
    //         handleMouseOver();
    //     }
    // }, 50), [handleMouseOut, handleMouseOver])

    // keep track of the mouse position
    // const mousePositionRef = useRef<{x: number, y: number} | null>(null)
    // // const mouseMoveHandler = useMemo(() => debounce((e: MouseEvent) => {
    // //     mousePositionRef.current = {x: e.clientX, y: e.clientY}
    // // }, 100), [])
    // useEffect(() => {
    //     if(hovered) {
    //         const mouseMoveHandler = (e: MouseEvent) => {
    //             mousePositionRef.current = {x: e.clientX, y: e.clientY}
    //             // console.log("pos", {x: e.clientX, y: e.clientY}, e.x, e.y, ref.current?.getBoundingClientRect())
    //         }
    //         window.addEventListener("mousemove", mouseMoveHandler)
    //         return () => {
    //             window.removeEventListener("mousemove", mouseMoveHandler)
    //         }
    //     }
    // }, [hovered])


    // listen to dom changes
    // useEffect(() => {
    //     if(hovered) {
    //         const config = { attributes: true, childList: true, subtree: true };
    //
    //         // Create an observer instance linked to the callback function
    //         const observer = new MutationObserver((mutationsList, observer) => {
    //
    //             // console.log("new mutations", mutationsList)
    //             if (!ref.current) return;
    //             const rect = ref.current.getBoundingClientRect();
    //             if (mousePos.x < rect.left || mousePos.x > rect.right || mousePos.y < rect.top || mousePos.y > rect.bottom) {
    //                 // console.log("mouse out", mousePos, rect)
    //                 handleMouseOut();
    //             } else {
    //                 // console.log("mouse over", mousePos, rect)
    //                 handleMouseOver();
    //             }
    //         });
    //
    //         // Start observing the target node for configured mutations
    //         observer.observe(window.document, config);
    //         return () => {
    //             observer.disconnect()
    //         }
    //     }
    // }, [handleMouseOver, handleMouseOut, hovered])

    return [callbackRef, hovered] as const
}
