import React from "react";
import {Icon} from '@iconify/react';
import {AvatarButton} from "./avatarButton";


export function UserAvatar({name, size = "medium"}: {
    name: string | null,
    size?: "medium" | "small",
}) {

    let initials: null | string = null
    if (name) {
        const {firstName} = getFirstAndLastName(name)
        initials = firstName[0]
    }

    return (
        <AvatarButton size={size}>
            {
                initials ?
                    initials :
                    <Icon icon="mdi:incognito"/>
            }
        </AvatarButton>
    )
}

export function getFirstAndLastName(name: string) {
    const nameSplit = name.split(' ')
    return {
        firstName: nameSplit[0] as string,
        lastName: nameSplit[1] ?? ""
    }
}
