import React from 'react'
import { parseISO, formatDistanceToNow } from "date-fns"

export function convertTimeToTimeAgo(timestamp: number) {
    const date = parseISO(new Date(timestamp).toISOString())
    const timePeriod = formatDistanceToNow(date)
    return `${timePeriod} ago`
}


export const TimeAgo = (props:{ timestamp:number }) => {
    let timeAgo = ''
    if (props.timestamp) {
        const date = parseISO(new Date(props.timestamp).toISOString())
        const timePeriod = formatDistanceToNow(date)
        timeAgo = `${timePeriod} ago`
    }

    return timeAgo
    // return (
    //     <span title={props.timestamp}>
    //   &nbsp; <i>{timeAgo}</i>
    // </span>
    // )
}
