import {v, compile} from "suretype"
import type {TypeOf} from "suretype"
import {idSchema} from "./utils/schema";

export interface CommentFirestoreData {
    authorId: string,
    authorName: string | null, // null if incognito
    body: string | null, // null if comment deleted
    createdOn: number,
    updatedOn: number | null,
    reactions: Record<string, string[]>, // <emoji, userIds>
    replyTo: string | null,
}

export function createCommentFromArgs(
    member: { id: string, name: string },
    args: CreateChatCommentArgs,
) {
    return {
        authorId: member.id,
        authorName: args.incognito ? null : member.name,
        body: args.body,
        reactions: {},
        createdOn: (new Date()).getTime(),
        updatedOn: null,
        replyTo: args.replyTo,
    } as CommentFirestoreData
}

// --------------------------
// constants
// --------------------------

export const MAX_CHAT_COMMENT_TITLE_LENGTH = 400

export const MAX_CHAT_COMMENT_BODY_LENGTH = 6000

// --------------------------
// create comment title
// --------------------------

export const createTitleArgsSchema = v.object({
    text: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
})
export type CreateTitleArgs = TypeOf<typeof createTitleArgsSchema>
export const createTitleArgsValidator = compile(createTitleArgsSchema)

// --------------------------
// create
// --------------------------

export const createChatCommentArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required(),
    body: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
    replyTo: idSchema.required(),
    incognito: v.boolean().required(),
})
export type CreateChatCommentArgs = TypeOf<typeof createChatCommentArgsSchema>
export const createChatCommentArgsValidator = compile(createChatCommentArgsSchema)

// --------------------------
// delete
// --------------------------

export const deleteChatCommentArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required()
})
export type DeleteChatCommentArgs = TypeOf<typeof deleteChatCommentArgsSchema>
export const deleteChatCommentArgsValidator = compile(deleteChatCommentArgsSchema)

// --------------------------
// update
// --------------------------

export const updateChatCommentArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required(),
    body: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
})
export type UpdateChatCommentArgs = TypeOf<typeof updateChatCommentArgsSchema>
export const updateChatCommentArgsValidator = compile(updateChatCommentArgsSchema)


// --------------------------
// toggle reaction
// --------------------------

// NOTE we do not offer thumbs down symbol because
// instead of giving a thumbs down, one should give a reason
// why the corresponding post is bad and then thumbs up this comment
export const chatReactions = ["👍", "🔥", "🙏", "❤️", "😂", "😮", "😔", "😠"] as const
const chatReactionSchema = v.string().enum(...chatReactions)
export type chatReactionType = TypeOf<typeof chatReactionSchema>

export const toggleChatCommentReactionArgsSchema = v.object({
    // cmd: v.string().const("toggleChatCommentReaction").required(),
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required(),
    reaction: chatReactionSchema.required(),
    value: v.boolean().required()
})
export type ToggleChatCommentReactionArgs = TypeOf<typeof toggleChatCommentReactionArgsSchema>
export const toggleChatCommentReactionArgsValidator = compile(toggleChatCommentReactionArgsSchema)





