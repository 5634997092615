import {collection, doc} from "firebase/firestore";
import {firestoreDb} from "./firebase";


function getFirestoreCommunityRef(communityId: string) {
    return doc(firestoreDb, "communities", communityId)
}

export function getFirestoreMemberRef({communityId, memberId}: {
    communityId: string,
    memberId: string
}){
    return doc(getFirestoreCommunityRef(communityId), "members", memberId)
}

export function getFirestoreMemberSecretsRef({communityId, memberId}: {
    communityId: string,
    memberId: string
}){
    return doc(getFirestoreCommunityRef(communityId), "memberSecrets", memberId)
}

export function getFirestoreChannelRef({communityId, channelId}: {
    communityId: string,
    channelId: string
}) {
    return doc(getFirestoreCommunityRef(communityId), "channels", channelId)
}

export function getFirestoreThreadsColRef(args: {
    communityId: string,
    channelId: string
}) {
    return collection(getFirestoreChannelRef(args), "threads")
}

export function getFirestoreThreadRef({threadId, ...rest}: {
    communityId: string,
    channelId: string,
    threadId: string,
}) {
    return doc(getFirestoreThreadsColRef(rest), threadId)
}
