import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AuthSignedOut {
    isSignedIn: false,
}

interface AuthSignedIn {
    isSignedIn: true,
    uid: string,
}

export type Auth = {hasLoaded: boolean} & (AuthSignedOut | AuthSignedIn)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        hasLoaded: false,
        isSignedIn: false
    } as Auth,
    reducers: {
        signIn(state, action: PayloadAction<{
            uid: string,
            providerId: string
        }>) {
            state.hasLoaded = true
            state.isSignedIn = true
            if(state.isSignedIn) {
                state.uid = action.payload.uid
            }
        },
        signOut(state) {
            state.hasLoaded = true
            state.isSignedIn = false
        }
    }
})

export const {
    signIn,
    signOut,
} = authSlice.actions

export const authReducer = authSlice.reducer
