import {useCallback, useEffect, useMemo, useState} from "react";
import {SxProps} from "@mui/system";
import {TextField, InputAdornment, IconButton, Tooltip, CircularProgress} from "@mui/material";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import debounce from "lodash.debounce";

export function TitleForm({setTitle, title, sx, createTitle, titleSeedText, label = "Title", autoFocus = false, maxTitleLength}: {
    title: string,
    setTitle: (value: string) => void,
    maxTitleLength?: number | undefined,
    sx?: SxProps,
    createTitle: (text: string) => Promise<{ title: string }>,
    titleSeedText: string,
    label?: string,
    autoFocus?: boolean
}) {

    const [useAi, setUseAi] = useState(true)

    const [isLoading, setIsLoading] = useState(false)

    const fetchTitle = useCallback(async (text: string) => {
        if (text.length < 10) return
        setIsLoading(true)
        const res = await createTitle(text)
        setTitle(res.title)
        setIsLoading(false)
    }, [createTitle, setTitle])

    return (
        <TextField
            autoFocus={autoFocus}
            placeholder={`${label} (auto-generate one by clicking on the icon on the left)`}
            multiline
            fullWidth
            // label={label}
            value={title}
            onChange={(event) => {
                if(maxTitleLength && event.target.value.length > maxTitleLength) return
                setTitle(event.target.value)
            }}
            variant="outlined"
            sx={sx}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={() => {
                            fetchTitle(titleSeedText)
                        }}>
                            {
                                isLoading ?
                                    <CircularProgress size={24}/> :
                                    <Tooltip title={titleSeedText.length < 10 ? "Type at least 10 chars for auto title generation" : "Generate title"}>
                                        <AutoAwesomeOutlinedIcon/>
                                    </Tooltip>
                            }
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )

}
