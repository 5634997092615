import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {RootState} from "../store";
import {Member, MemberSecrets} from "./reduxTypes";


const memberSecretsEntityAdapter = createEntityAdapter<MemberSecrets>()

const memberSecretsSlice = createSlice({
    name: "memberSecrets",
    initialState: memberSecretsEntityAdapter.getInitialState({}),
    reducers: {
        addMemberSecrets: memberSecretsEntityAdapter.addOne,
        upsertMemberSecrets: memberSecretsEntityAdapter.upsertOne,
        updateMemberSecrets: memberSecretsEntityAdapter.updateOne,
        removeMemberSecrets: memberSecretsEntityAdapter.removeOne,
        removeAllMemberSecrets: memberSecretsEntityAdapter.removeAll,
        setAllMemberSecrets: memberSecretsEntityAdapter.setAll
    }
})

export const {
    addMemberSecrets,
    upsertMemberSecrets,
    updateMemberSecrets,
    removeMemberSecrets,
    removeAllMemberSecrets,
    setAllMemberSecrets
} = memberSecretsSlice.actions

export const memberSecretsReducer = memberSecretsSlice.reducer

export const {
    selectAll: selectAllMemberSecrets,
    selectById: selectMemberSecretsById,
    selectIds: selectMemberSecretsIds,
} = memberSecretsEntityAdapter.getSelectors<RootState>(state => state.memberSecrets)

