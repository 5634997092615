import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {Channel} from "../slices/reduxTypes";
import {ChannelFirestoreData} from "../../shared";
import {firestoreManager} from "../../firebase/queryManager";
import {setChannel} from "../slices/channelSlice";
import useMemoizedState from "./useMemoizedState";
import {getFirestoreChannelRef} from "../../firebase/firestoreReferences";
import {discunaCoreFrontendApiClient} from "../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {cancelablePromise} from "../../utils/cancelablePromise";

export function useChannel({communityId, channelId}: {
    communityId: string,
    channelId: string
}) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(false)

    const [channelName, setChannelName] = useState<string | null>(null)

    useEffect(() => {
        const [task, cancel] = cancelablePromise(discunaCoreFrontendApiClient.getChannelName())
        task.then(channelName => {
            setChannelName(channelName)
        }).catch((e) => {
            console.error("error fetching channel name:", e)
        })
        return cancel
    }, [])

    // fetch channel
    useEffect(() => {
        if(!channelName) return
        const channelDocRef = getFirestoreChannelRef({communityId, channelId})
        const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(
            channelDocRef,
            (doc) => {
                setIsLoading(false)
                if (doc.exists()) {
                    const channelData = {
                        id: doc.id,
                        isSynced: true,
                        name: channelName,
                        ...(doc.data() as ChannelFirestoreData)
                    } as Channel
                    dispatch(setChannel(channelData))
                } else {
                    dispatch(setChannel(null))
                }
            })
        setIsLoading(isNew)
        return cancel
    }, [dispatch, channelId, communityId, setIsLoading, channelName])
    return isLoading
}
