import React, {useState} from 'react';
import {MotionBox} from "../../../../common/motion_mui";
import {SxProps} from "@mui/system";


export function ChatItemContextButton({label, icon, sx, onClick, disabled}: {
    icon: React.ReactNode, // Emoji represented as a string
    sx?: SxProps,
    label?: string,
    onClick: () => void,
    disabled?: boolean
}) {
    const [buttonState, setButtonState] = useState<null | "hovered" | "clicked">(null)

    return (
        // https://medium.com/@seanmcp/%EF%B8%8F-how-to-use-emojis-in-react-d23bbf608bf7
        <MotionBox
            animate={{
                scale: buttonState === "hovered" && !disabled ? 1.4 : 1
            }}
            sx={{px: 0.5, cursor: "pointer", userSelect: "none", ...sx}}
            onMouseEnter={() => setButtonState("hovered")}
            onMouseLeave={() => setButtonState(null)}
            onMouseDown={() => setButtonState("clicked")}
            onMouseUp={() => setButtonState("hovered")}
            onClick={() => {
                if(!disabled) onClick()
            }}
        >
            {icon}
        </MotionBox>
    )
}


