import {compile, TypeOf, v} from "suretype";

// --------------------------
// events
// --------------------------

const notificationClickActionSchema = v.object({
    cmd: v.string().const("notificationClick").required(),
    payload: v.object({
        notificationId: v.string().required(),
        data: v.any().required()
    }).required()
})
export type NotificationClickAction = TypeOf<typeof notificationClickActionSchema>
export const isNotificationClickAction = compile(notificationClickActionSchema, {simple: true})

const createChannelClickActionSchema = v.object({
    cmd: v.string().const("createChannelClick").required()
})
export type CreateChannelClickAction = TypeOf<typeof createChannelClickActionSchema>
export const isCreateChannelClickAction = compile(createChannelClickActionSchema, {simple: true})
