import {useSelector} from "react-redux";
import {RootState} from "../store";
import {selectMemberById} from "../slices/memberSlice";
import {useChannel} from "../hooks/useChannel";
import {useParams} from "react-router-dom";
import {useMember} from "../hooks/useMember";
import {LoadingScreen} from "../components/common/loadingScreen";
import {ChatChannelApp} from "../components/channel/chatChannelApp";
import {useMemberSecrets} from "../hooks/useMemberSecrets";
import {selectMemberSecretsById} from "../slices/memberSecretsSlice";

// NOTE this component is general and can be used for different kinds of channels
export function ChatChannel() {
    const {communityId, channelId} = useParams()
    if(!communityId || !channelId) throw Error("url malformed")

    const auth = useSelector((state: RootState) => state.auth)
    if(!auth.isSignedIn) throw Error("unauthenticated")

    // fetch member
    const memberIsLoading = useMember({memberId: auth.uid, communityId})
    const member = useSelector((state: RootState) => selectMemberById(state, auth.uid))

    // fetch member secrets
    const memberSecretsIsLoading = useMemberSecrets({memberId: auth.uid, communityId})
    // NOTE memberSecrets might not exist
    // const memberSecrets = useSelector((state: RootState) => selectMemberSecretsById(state, auth.uid))

    // fetch channel
    const channelIsLoading = useChannel({communityId, channelId})
    const channel = useSelector((state: RootState) => state.channel.channel)

    if (channelIsLoading || memberIsLoading || !member || !channel) return <LoadingScreen/>
    return <ChatChannelApp member={member} channel={channel} communityId={communityId}/>
}
