import {Typography} from "@mui/material";
import {Row} from "./motion_mui";
import React from "react";
import {darkGrey, lightGrey, primaryColor} from "../../constants";
import {getTimeString} from "../channel/comments/commentUtils";


export function UserName({name, timestamp}: {
    name: string | null,
    timestamp: number
}) {
    return (
        <Row mainAxisAlignment={"start"} crossAxisAlignment={"baseline"}>
            <Typography style={{fontWeight: 500, whiteSpace: "nowrap", color: primaryColor}} variant={"body1"}>
                {name??"Incognito"}
            </Typography>
            <Typography variant={"body1"} sx={{
                color: darkGrey,
                ml: 1,
                whiteSpace: "nowrap"
            }}>
                {getTimeString(timestamp)}
            </Typography>
            {/*<Typography*/}
            {/*    variant={"body2"}*/}
            {/*    sx={{ml: 1, color: darkGrey, whiteSpace: "nowrap"}}*/}
            {/*>*/}
            {/*    {TimeAgo({timestamp})}*/}
            {/*</Typography>*/}
        </Row>
    )

}
