import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup, Snackbar,
    TextField,
    Tooltip
} from "@mui/material";
import {ModalTitle, ModalWithPresence} from "../common/modal";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {CheckboxWithTooltip} from "../common/checkboxWithTooltip";
import {updateChatChannel} from "../../chatAppApi";
import {CustomLoadingButton} from "../common/customLoadingButton";
import {MarkdownEditor} from "../markdownEditor/markdownEditor";


export function EditChannelModal({onClose, show, channelId, communityId}: {
    onClose: () => void,
    channelId: string,
    communityId: string,
    show: boolean
}) {
    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <EditChannel onClose={onClose} channelId={channelId} communityId={communityId}/>
        </ModalWithPresence>
    )
}

function EditChannel({onClose, channelId, communityId}: {
    onClose: () => void,
    channelId: string,
    communityId: string
}) {

    // TODO get channel

    // const community = useAppSelector((state) => selectCommunityById(state, communityId))
    const channel = useAppSelector((state) => state.channel.channel)
    if(!channel) throw Error("channel not found")



    const oldName = channel.name
    const oldDescription = channel.details.description

    const [name, setName] = useState(oldName ?? "")
    const [description, setDescription] = useState(oldDescription ?? "")

    const disabled = name.length === 0 || name.length > 60 ||
        (
            oldName === name &&
            oldDescription === description
        )

    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState<string | null>(null)

    return (
        <>
            <ModalTitle title={"Edit Channel"} onCancel={onClose}/>

            <Tooltip title={"To change the channel name, please make a left click on the channel in the navigation bar."}>
                <TextField
                    disabled={true}
                    key={"channelName"}
                    label={"Channel name"}
                    value={name}
                    variant={"filled"}
                    onChange={(e) => {
                        setName(e.currentTarget.value)
                    }}/>
            </Tooltip>

            {/*<TextField*/}
            {/*    key={"channelDescription"}*/}
            {/*    sx={{mt: 2}}*/}
            {/*    variant={"filled"}*/}
            {/*    label={"Brief channel description"}*/}
            {/*    value={description}*/}
            {/*    onChange={(e) => {*/}
            {/*        setDescription(e.currentTarget.value)*/}
            {/*    }}/>*/}
            <MarkdownEditor placeholder={"Channel description"} sx={{mt: 2}}
                            markdown={description}
                            onChange={(value) => setDescription(value.slice(0, 2000))}/>

            <CheckboxWithTooltip
                sx={{alignSelf: "start", mt: 1}}
                isChecked={channel.details.commentsRequireAction}
                label={"Ticket system"}
                tooltipTitle={"Currently, this option cannot be changed."}
                disabled={true}
            />

            <CheckboxWithTooltip
                sx={{alignSelf: "start"}}
                isChecked={channel.details.permissions.write?.roles.length !== 0}
                label={"Announcement system"}
                tooltipTitle={"Currently, this option cannot be changed."}
                disabled={true}
            />

            <CustomLoadingButton
                isLoading={processing}
                error={error}
                variant={"contained"}
                sx={{mt: 2, alignSelf: "end"}}
                disabled={disabled}
                onClick={() => {
                    setProcessing(true)
                    setError(null)
                    updateChatChannel({
                        channelId: channelId,
                        communityId: communityId,
                        description: description
                    }).then(() => {
                        setProcessing(false)
                    }).catch((e) => {
                        setError("Error: " + e.message)
                    }).finally(() => {
                        setProcessing(false)
                    })
                }}
            >
                Update
            </CustomLoadingButton>

        </>
    )
}
