
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import React, {useState} from "react";
import {ANIMATE_STROKE, StrokeProps} from "./utils/stroke";
import {Indent} from "./utils/indent";
import {ChatItemContainerMotion} from "./chatItemContainer";

import {AvatarButton} from "../../../common/avatarButton";
import {ChatPostData, CommentForm} from "../commentForm";

export function ChatReplyItem({onSend, onCancel, replyTo, indented, getMediaFolderUrl, maxReplyLength, ...strokeProps}: {
    onSend: (data: ChatPostData) => void,
    onCancel: () => void,
    replyTo: string,
    indented?: boolean,
    getMediaFolderUrl: () => string,
    maxReplyLength?: number | undefined,
} & StrokeProps) {

    const [body, setBody] = useState("")
    const [showName, setShowName] = useState(true)

    const item =
        <ChatItemContainerMotion
            layoutId={!ANIMATE_STROKE ? undefined : `replyTo-${replyTo}`}
            replyTo={replyTo}
            isSingleChild={!indented}
            avatar={
                <AvatarButton
                    disabled={body.length === 0}
                    onClick={() => {
                        onSend({body, showName})
                    }}
                >
                    <ReplyOutlinedIcon/>
                </AvatarButton>
            }
            item={
                <CommentForm
                    maxCommentLength={maxReplyLength}
                    getMediaFolderUrl={getMediaFolderUrl}
                    placeholder={"Post a reply"}
                    upliftedState={{
                        body,
                        setBody,
                        showName,
                        setShowName
                    }}
                    onSend={onSend}
                    focusOnMount={indented}
                    onCancel={onCancel}
                />
            }
            {...strokeProps}
        />

    if (!indented) return item
    return (
        <Indent
            layoutId={`indent-reply-${replyTo}`}
            isLastChild={false}
            item={item}
            {...strokeProps}
        />
    )
}
