import React, {useState} from "react";
import {Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {Row} from "../common/motion_mui";

export function EditorFooter({onSend, sendDisabled, sendMode = "post", hideCancel, onCancel, upliftedState}: {
    onSend: (data: {showName: boolean}) => void,
    sendDisabled: boolean,
    sendMode?: "post" | "edit",
    hideCancel?: boolean,
    onCancel?: () => void,
    upliftedState?: {
        showName: boolean,
        setShowName: (showName: boolean) => void
    },
}) {

    const [showName, setShowName] = useState(true)

    return (
        <Row sx={{mt: 1}} mainAxisAlignment={sendMode === "post" ? "spaceBetween" : "end"}
             crossAxisAlignment={"center"}>
            {
                sendMode === "post" &&
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={upliftedState ? upliftedState.showName : showName}
                                onChange={(e) => {
                                    if(upliftedState) upliftedState.setShowName(e.currentTarget.checked)
                                    else setShowName(e.currentTarget.checked)
                                }}
                            />
                        }
                        label="Show name"
                    />
                </FormGroup>
            }
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                {
                    !hideCancel &&
                    <Button onClick={() => {
                        if(upliftedState) upliftedState.setShowName(true)
                        else setShowName(true)
                        if (onCancel) onCancel()
                    }}>
                        Cancel
                    </Button>
                }
                <Button
                    disabled={sendDisabled}
                    variant={"contained"}
                    sx={{ml: 2}}
                    onClick={() => {
                        onSend({showName})
                    }}
                >
                    {sendMode === "post" ? "Send" : "Update"}
                </Button>
            </Row>
        </Row>
    )
}
