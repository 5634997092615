import {Center, FullSizedWindow} from "./motion_mui";
import {CircularProgress} from "@mui/material";

export function LoadingScreen() {
    return <FullSizedWindow visible={true}>
        <Center>
            <CircularProgress/>
        </Center>
    </FullSizedWindow>
}
