import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import React from "react";
import {StrokeProps} from "./utils/stroke";
import {ChatItemStrokes} from "./chatItemStrokes";
import {motion} from "framer-motion";
import {Column, Row, RowMotion} from "../../../common/motion_mui";
import {superLightGrey} from "../../../../constants";
import {Box} from "@mui/material";

export const ChatItemContainer = React.forwardRef<HTMLDivElement, {
    avatar: ReactJSXElement,
    item: ReactJSXElement,
    // isHovered?: boolean,
    // setIsHovered?: (value: boolean) => void,
    replyTo: string | null,
    isSingleChild: boolean,
    chatCommentId?: string,
    hovered?: boolean,
    backgroundColor?: string,
} & StrokeProps>((
    {
        avatar,
        item,
        // isHovered,
        // setIsHovered,
        replyTo,
        chatCommentId,
        isSingleChild,
        hovered,
        backgroundColor,
        // threads
        ...strokeProps
    },
    ref
) => {

    return (
        <Box sx={{
            bgcolor: backgroundColor,
            transition: "all 2s",
            width: "100%"
        }}>
            <RowMotion
                ref={ref}
                mainAxisAlignment={"start"}
                crossAxisAlignment={"stretch"}
                sx={{
                    p: 2,
                    flexGrow: 1,
                    bgcolor: hovered ? superLightGrey : "inherit",
                    position: "relative",
                    wordBreak: "break-word"
                }}
                // onMouseOver={() => setIsHovered && setIsHovered(true)}
                // onMouseOut={() => setIsHovered && setIsHovered(false)}
                // onMouseOut={() => setIsHovered && setIsHovered(false)}
            >
                <Row
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"stretch"}
                    sx={{
                        position: "relative",
                    }}
                >
                    <ChatItemStrokes
                        replyTo={replyTo}
                        isSingleChild={isSingleChild}
                        chatCommentId={chatCommentId}
                        {...strokeProps}
                    />
                    {avatar}
                </Row>
                <Column
                    sx={{
                        pl: 2,
                        flexGrow: 1,
                        position: "relative",
                        // maxWidth: "calc(100% - 48px)",
                        // px: 2
                        // overflow: "hidden",
                        // minHeight: "72px"
                    }}
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"stretch"}
                >
                    {item}
                </Column>
            </RowMotion>
        </Box>
    )
})

export const ChatItemContainerMotion = motion(ChatItemContainer)
