import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {selectChatThreadById} from "../../slices/chatThreadSlice";
import {Column, Row} from "../common/motion_mui";
import {ChatThreadComments} from "./comments/chatThreadComments";
import React, {useCallback, useMemo, useState} from "react";
import {
    createChatComment, createTitle,
    deleteChatComment,
    generateId, setThreadBookmark, setThreadSolved,
    toggleChatCommentReaction, toggleThreadSubscription,
    updateChatComment, updateThreadTitle
} from "../../chatAppApi";
import {ChatPostData} from "./comments/commentForm";
import {chatReactionType, MAX_CHAT_COMMENT_BODY_LENGTH} from "../../../shared";
import {Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import {STROKE_COLOR} from "./comments/chatItems/utils/stroke";
import {SxProps} from "@mui/system";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInNotOutlined';
import TurnedInOutlinedIcon from '@mui/icons-material/TurnedInOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import {selectMemberById} from "../../slices/memberSlice";
import {TitleForm} from "../markdownEditor/titleForm";
import {MAX_CHAT_THREAD_TITLE_LENGTH} from "../../../shared/thread";
import {getLicenseId} from "../../../discunaAppToolkit/utils/licenseUtils";
import {LICENSE_ID_MODERATOR} from "../../appEnv";
import {selectMemberSecretsById} from "../../slices/memberSecretsSlice";

export function ChatThread({
                               communityId,
                               channelId,
                               threadId,
                               getMediaFolderUrl,
                               highlight,
                               setHighlight,
                               sx,
                               initiallyCollapsed
                           }: {
    communityId: string,
    channelId: string,
    // do not pass in whole thread to avoid re-rendering when other threads change
    threadId: string,
    getMediaFolderUrl: () => string,
    highlight: string | null,
    setHighlight: (value: string | null) => void,
    sx?: SxProps,
    initiallyCollapsed?: boolean | undefined
}) {
    const thread = useSelector((state: RootState) => selectChatThreadById(state, threadId))
    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) throw Error("Not signed in")
    const member = useSelector((state: RootState) => selectMemberById(state, auth.uid))

    const dispatch = useDispatch()

    // callbacks
    const onReply = useCallback((replyTo: string, data: ChatPostData) => {
        dispatch(createChatComment({
            communityId,
            channelId,
            threadId,
            commentId: generateId(),
            replyTo,
            body: data.body,
            incognito: !data.showName
        }));
    }, [channelId, communityId, dispatch, threadId])

    const onUpdateComment = useCallback((commentId: string, body: string) => {
        dispatch(updateChatComment({
            communityId,
            channelId,
            threadId,
            commentId,
            body
        }));
    }, [channelId, communityId, dispatch, threadId])

    const onDeleteComment = useCallback((commentId: string) => {
        dispatch(deleteChatComment({
            communityId,
            channelId,
            threadId,
            commentId
        }))
    }, [channelId, communityId, dispatch, threadId])

    const onToggleEmoji = useCallback((commentId: string, emoji: chatReactionType, value: boolean) => {
        dispatch(toggleChatCommentReaction({
            communityId,
            channelId,
            threadId,
            commentId,
            reaction: emoji,
            value
        }))
    }, [channelId, communityId, dispatch, threadId])

    const [setSolvedIsLoading, setSetSolvedIsLoading] = useState(false)
    const onSetThreadSolved = useCallback(async (value: boolean) => {
        setSetSolvedIsLoading(true)
        try {
            await setThreadSolved({
                communityId,
                channelId,
                threadId,
                solved: value
            })
        } catch (e) {
            console.error("Error setting thread solved property", e)
        } finally {
            setSetSolvedIsLoading(false)
        }
    }, [channelId, communityId, threadId])

    const isSubscribed = useMemo(() => {
        if (!thread || !auth.isSignedIn) return false
        return thread.details.subscribers.includes(auth.uid)
    }, [auth, thread])
    const onToggleThreadSubscription = useCallback(() => {
        dispatch(toggleThreadSubscription({
            communityId,
            channelId,
            threadId,
            isSubscribed: !isSubscribed
        }))
    }, [channelId, communityId, dispatch, isSubscribed, threadId])

    const memberSecrets = useSelector((state: RootState) => selectMemberSecretsById(state, auth.uid))
    type Bookmark = "" | "interesting" | "todo"
    // const [bookmarked, setBookmarked] = useState<Bookmark>("")
    const bookmarked = useMemo(() => {
        if (!memberSecrets) return ""
        if (memberSecrets.details.bookmarks.todo.includes(threadId)) {
            return "todo"
        }
        if (memberSecrets.details.bookmarks.interesting.includes(threadId)) {
            return "interesting"
        }
        return ""
    }, [memberSecrets, threadId])
    const [settingBookmark, setSettingBookmark] = useState(false)
    const onSetThreadBookmark = useCallback(async (bookmark: Bookmark) => {
        setSettingBookmark(true)
        try {
            await setThreadBookmark({
                communityId,
                channelId,
                threadId,
                bookmark
            })
        } catch (e) {
            console.error("Error setting thread solved property", e)
        } finally {
            setSettingBookmark(false)
        }
    }, [channelId, communityId, threadId])
    const [bookmarkAnchorEl, setBookmarkAnchorEl] = React.useState<null | HTMLElement>(null);


    const onUpdateThreadTitle = useCallback((title: string) => {
        dispatch(updateThreadTitle({
            communityId,
            channelId,
            threadId,
            title
        }))
    }, [channelId, communityId, dispatch, threadId])
    const [title, setTitle] = useState("")
    const [editingTitle, setEditingTitle] = useState(false)
    const createTitleCallback = useCallback(async (text: string) => {
        const res = await createTitle({text})
        return {title: res.data.title}
    }, [])

    const isAuthor = useMemo(() => {
        if (!thread) return false
        return thread.details.comments[threadId].authorId === auth.uid
    }, [auth, thread, threadId])
    // const memberLicenseId = useMemo(() => {
    //     if (!member) return null
    //     return getLicenseId(member.details.licenses??[])
    // }, [member])
    const hasModeratorPrivileges = useMemo(() => {
        if (!member) return false
        return ["administrator", "moderator"].some(role => member.details.roles.includes(role))
    }, [member])
    // const showLicenseWarning = useMemo(() => {
    //     return hasModeratorPrivileges && memberLicenseId !== LICENSE_ID_MODERATOR
    // }, [hasModeratorPrivileges, memberLicenseId])
    const showLicenseWarning = false
    const canSetThreadSolved = useMemo(() => {
        return isAuthor || (member && hasModeratorPrivileges) // memberLicenseId === LICENSE_ID_MODERATOR
    }, [isAuthor, member, hasModeratorPrivileges])

    if (!thread || !auth.isSignedIn) return null
    return (
        <Column sx={{
            width: "100%",
            ...sx
        }} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            {/*<Divider sx={{*/}
            {/*    minHeight: 1,*/}
            {/*    width: "100%"*/}
            {/*}}/>*/}
            <Row
                mainAxisAlignment={"spaceBetween"}
                crossAxisAlignment={"center"}
                // className={"coolShade"}
                sx={{
                    width: "100%",
                    maxWidth: "100%",
                    px: 2,
                    py: 0.5,
                    // mx: 2,
                    bgcolor: STROKE_COLOR,
                    // borderTop: `2px solid ${STROKE_COLOR}`,
                    // borderBottom: `2px solid ${STROKE_COLOR}`,
                    // borderRadius: "8px 8px 0px 0px",
                    // bgcolor: "rgb(249,252,255)",
                    // background: "background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(92,63,177,1) 100%)",
                }}
            >
                {
                    editingTitle ?
                        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                            flexGrow: 1,
                            // mt: 0.5,
                            mb: 1,
                        }}>
                            <TitleForm
                                maxTitleLength={MAX_CHAT_THREAD_TITLE_LENGTH}
                                autoFocus={true}
                                label={"Thread title"}
                                sx={{mt: 1}}
                                title={title}
                                setTitle={setTitle}
                                createTitle={createTitleCallback}
                                titleSeedText={thread.details.comments[threadId].body ?? ""}
                            />
                            <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}
                                 sx={{mt: 1, alignSelf: "end"}}>
                                <Button sx={{mr: 2}} onClick={() => setEditingTitle(false)}>
                                    Cancel
                                </Button>
                                <Button variant={"contained"} disabled={title.length === 0} onClick={() => {
                                    onUpdateThreadTitle(title)
                                    setEditingTitle(false)
                                }}>
                                    Update
                                </Button>
                            </Row>
                        </Column> :
                        isAuthor ?
                            <Tooltip title={"Click to edit thread title"}>
                                <Typography variant={"body1"} onClick={() => {
                                    setTitle(thread.details.title)
                                    setEditingTitle(true)
                                }}>
                                    {thread.details.title}
                                </Typography>
                            </Tooltip> :
                            <Typography variant={"body1"}>
                                {thread.details.title}
                            </Typography>

                }
                {
                    !editingTitle &&
                    <Row
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"start"}
                        // sx={{
                        //     position: "absolute",
                        //     top: 0,
                        //     right: 16
                        // }}
                        sx={{
                            alignSelf: "start",
                            ml: 1
                        }}
                    >
                        {
                            thread.solved !== undefined &&
                            (
                                canSetThreadSolved ?
                                    thread.solved ?
                                        <Tooltip title={"Thread is solved. Click to mark thread as unsolved."}>
                                            <span>
                                                <IconButton disabled={setSolvedIsLoading} size={"small"}
                                                            onClick={() => onSetThreadSolved(false)}>
                                                    <CheckBoxOutlinedIcon/>
                                                </IconButton>
                                            </span>
                                        </Tooltip> :
                                        <Tooltip title={"Thread requires action. Click to mark thread as solved."}>
                                            <span>
                                                <IconButton disabled={setSolvedIsLoading} size={"small"}
                                                            onClick={() => onSetThreadSolved(true)}>
                                                    <CheckBoxOutlineBlankOutlinedIcon sx={{color: "red"}}/>
                                                </IconButton>
                                            </span>
                                        </Tooltip> :
                                    thread.solved ?
                                        <Tooltip
                                            title={"Thread is solved." + (showLicenseWarning && " You need a moderator license to mark threads from other members as unsolved.")}>
                                            <IconButton disableTouchRipple size={"small"} sx={{cursor: "inherit"}}>
                                                <CheckBoxOutlinedIcon/>
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip
                                            title={"Thread requires action." + (showLicenseWarning && " You need a moderator license to mark threads from other members as solved.")}>
                                            <IconButton disableTouchRipple size={"small"} sx={{cursor: "inherit"}}>
                                                <CheckBoxOutlineBlankOutlinedIcon sx={{color: "red"}}/>
                                            </IconButton>
                                        </Tooltip>
                            )
                        }
                        <Tooltip title={bookmarked === "" ?
                            "Click to bookmark this thread." :
                            `You bookmarked this thread as ${bookmarked}. Click to remove bookmark.`
                        }>
                            <span>
                                <IconButton disabled={settingBookmark} size={"small"} edge={"end"} onClick={(e) => {
                                    if (bookmarked === "") {
                                        setBookmarkAnchorEl(e.currentTarget)
                                    } else {
                                        onSetThreadBookmark("")
                                    }
                                }}>
                                {
                                    bookmarked === "" ?
                                        <TurnedInNotOutlinedIcon/> :
                                        <TurnedInOutlinedIcon sx={{
                                            color: bookmarked === "interesting" ? "orange" : "green"
                                        }}/>
                                }
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={isSubscribed ?
                            "You are subscribed. Click to unsubscribe. We will still notify you about new replies to your comments in this thread." :
                            "You are not subscribed. Click to subscribe. We will notify you about all new comments in this thread."
                        }>
                            <IconButton size={"small"} edge={"end"} onClick={onToggleThreadSubscription}>
                                {
                                    isSubscribed ?
                                        <NotificationsIcon/> :
                                        <NotificationAddOutlinedIcon/>
                                }
                            </IconButton>
                        </Tooltip>
                        <Menu
                            open={Boolean(bookmarkAnchorEl)}
                            anchorEl={bookmarkAnchorEl}
                            onClose={() => {
                                setBookmarkAnchorEl(null)
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={() => {
                                onSetThreadBookmark("todo")
                                setBookmarkAnchorEl(null)
                            }}>
                                <ListItemIcon>
                                    <FiberManualRecordIcon fontSize="small" sx={{
                                        color: "green"
                                    }}/>
                                </ListItemIcon>
                                <ListItemText>Mark as todo</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                onSetThreadBookmark("interesting")
                                setBookmarkAnchorEl(null)
                            }}>
                                <ListItemIcon>
                                    <FiberManualRecordIcon fontSize="small" sx={{
                                        color: "orange"
                                    }}/>
                                </ListItemIcon>
                                <ListItemText>Mark as interesting</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Row>
                }
            </Row>

            <ChatThreadComments
                maxCommentLength={MAX_CHAT_COMMENT_BODY_LENGTH}
                userId={auth.uid}
                threadId={threadId}
                comments={thread.details.comments}
                onReply={onReply}
                onUpdateComment={onUpdateComment}
                onDeleteComment={onDeleteComment}
                getMediaFolderUrl={getMediaFolderUrl}
                onToggleEmoji={onToggleEmoji}
                highlight={highlight}
                setHighlight={setHighlight}
                initiallyCollapsed={initiallyCollapsed}
            />
        </Column>
    )
}
