import {useDispatch, useSelector} from "react-redux";
import useMemoizedState from "./useMemoizedState";
import {RootState} from "../store";
import {useEffect} from "react";
import {firestoreManager} from "../../firebase/queryManager";
import {MemberSecrets} from "../slices/reduxTypes";
import {MemberSecretsFirestoreData} from "../../shared";
import {getFirestoreMemberSecretsRef} from "../../firebase/firestoreReferences";
import {removeMemberSecrets, upsertMemberSecrets} from "../slices/memberSecretsSlice";

export function useMemberSecrets({memberId, communityId}: {
    memberId: string,
    communityId: string
}) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)


    // fetch community and community member data
    useEffect(() => {
        if (auth.isSignedIn) {
            const memberRef = getFirestoreMemberSecretsRef({communityId, memberId})
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(memberRef, (doc) => {
                setIsLoading(false)
                if (doc.exists()) {
                    const member = {
                        id: doc.id,
                        isSynced: true,
                        ...(doc.data() as MemberSecretsFirestoreData)
                    } as MemberSecrets
                    dispatch(upsertMemberSecrets(member))
                } else {
                    dispatch(removeMemberSecrets(memberId))
                }
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [memberId, communityId, dispatch, setIsLoading, auth])
    return isLoading
}
