export const log = (...message: any[]) => {
    if (window.parent !== window) {
        window.parent.postMessage({
            message: "DISCUNA-LOG",
            data: message
        }, "*")
    } else {
        console.log(...message)
    }
}
