
/**
 * Stores up to 500mb in memory (RAM)
 * When limit is exceeded, it removes the buffers
 * that have not been accessed for the longest time.
 *
 */

// 500 mb / 8 bit = 62'500'000
const MAX_LENGTH = 62500000

interface MediaBuffer {
    buff: Uint8Array,
    length: number,
    id: string,
    lastTimeAccessed: number,
}

class MediaBufferManager {
    _buffers: Map<string, MediaBuffer> = new Map<string, MediaBuffer>()
    _currLength: number = 0

    addBuffer(id: string, buffer: Uint8Array) {
        let newLength = this._currLength + buffer.length
        if (newLength > MAX_LENGTH) {
            // TODO we should maintain a sorted list...
            const buffersSortedByUsage = Array.from(this._buffers.values())
                .sort((a, b) =>
                    a.lastTimeAccessed < b.lastTimeAccessed ? -1 : 1)
            for(let b of buffersSortedByUsage) {
                this._buffers.delete(b.id)
                newLength -= b.length
                if(newLength <= MAX_LENGTH) break
            }
        }
        this._currLength = newLength
        this._buffers.set(id, {
            id,
            length: buffer.length,
            buff: buffer,
            lastTimeAccessed: (new Date()).getTime()
        })
    }

    getBuffer(id: string) {
        const buff = this._buffers.get(id)
        if(buff) {
            buff.lastTimeAccessed = (new Date()).getTime()
            return buff.buff
        }
        return null
    }
}

export const mediaBufferManager = new MediaBufferManager()

// @ts-ignore
window.mediaBufferManager = mediaBufferManager
