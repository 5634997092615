import React from "react";
import {theme} from "./theme";
import {Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {Create} from "./pages/create";
import {ChatChannel} from "./pages/chatChannel";

import {useAuth} from "./components/auth/authUtils";
import {Provider, useSelector} from "react-redux";
import {RootState, store} from "./store";
import {LoadingScreen} from "./components/common/loadingScreen";
import {OAuthCallback} from "./pages/oAuthCallback";
import {WithAuth} from "./components/auth/withAuth";
import {AUDIENCE, AUTHORITY, CLIENT_ID, REDIRECT_URI} from "./appEnv";
import {AuthProvider} from "react-oidc-context";
import {NotificationContext} from "./pages/notificationContext";

const oidcConfig = {
    authority: AUTHORITY,
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
};

export function App() {
    useAuth()
    const auth = useSelector((state: RootState) => state.auth)

    if (!auth.hasLoaded) return <LoadingScreen/>

    return (
        <AuthProvider {...oidcConfig}>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path={"discuna/communities/:communityId/channels/:channelId/create"}
                           element={<WithAuth audience={AUDIENCE} protectedComponent={<Create/>}/>}/>
                    <Route path={"discuna/communities/:communityId/channels/:channelId"}
                           element={<WithAuth audience={AUDIENCE} protectedComponent={<ChatChannel/>}/>}/>
                    <Route path={"discuna/communities/:communityId/channels/:channelId/notifications/:notificationId/context"}
                           element={<WithAuth audience={AUDIENCE} protectedComponent={<NotificationContext/>}/>}/>
                    <Route path={"discuna/callback/login"} element={<OAuthCallback/>}/>
                </Routes>
            </ThemeProvider>
        </AuthProvider>
    )
}
