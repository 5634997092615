import React, {ChangeEvent, DragEvent, ClipboardEvent, useEffect, useMemo, useRef, useState, useCallback} from "react";
import {Alert, Box, Divider, IconButton, Snackbar, useTheme} from "@mui/material";
import {TextareaAutosize} from '@mui/base';

import katex from 'katex';
import {MarkdownRenderer} from "./markdownRenderer";
import {SxProps, Theme} from '@mui/system';
import Compressor from 'compressorjs';


// icons
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {ArrowRightRounded} from "@mui/icons-material";
// import AttachFileIcon from '@mui/icons-material/AttachFile';
import debounce from "lodash.debounce";
import {getStorage, ref, uploadBytes} from "firebase/storage";
import {imageMimeType, imageMimeTypeToExtensionMap} from "../../../shared";
import {Column, Row} from "../common/motion_mui";
import {generateId} from "../../chatAppApi";
import {mediaBufferManager} from "../../mediaBufferManager";
import {CustomDivider} from "./customDivider";
import {log} from "../../../utils/log";
import {firebaseAuth} from "../../../firebase/firebase";

const MAX_IMAGE_SIZE = 2 * 1024 * 1024 // 2mb

function editorIsFocusedSxStyle(theme: any) {
    return {
        border: '2px solid ' + theme.palette.primary.main + ' !important',

        // margin: "1px"
        '&:hover': {
            borderColor: "rgba(0, 0, 0, 0.87)",
        },
    } as const
}

function editorIsNotFocusedSxStyle(theme: any) {
    return {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        // margin: "1px"
        '&:hover': {
            borderColor: "rgba(0, 0, 0, 0.87)",
        },
    } as const
}


export function joinClassnames(...classnames: string[]) {
    return classnames.filter(i => i).join(" ");
}

export function MarkdownEditorDemo() {
    const [markdown, setMarkdown] = useState("")
    return <div style={{padding: "12px"}}>
        <MarkdownEditor markdown={markdown} onChange={(value) => setMarkdown(value)}/>
    </div>
}

export function MarkdownEditor(
    {
        markdown,
        maxMarkdownLength,
        onChange,
        uploadDirectory,
        onUploadMedia,
        placeholder,
        sx,
        alternativeFocus,
        onBlur,
        onFocus,
        isActivated = true,
        focusOnMount,
        disabled,
    }: {
        markdown: string,
        maxMarkdownLength?: number | undefined,
        onChange: (value: string) => void,
        // TODO maybe we can do something about that we do not have to pass in this prop
        // TODO rename to channelId + communityId?
        uploadDirectory?: string, // disables upload
        onUploadMedia?: (mediaUrl: string) => void,

        // embeddedMediaURLs?: string[],
        // setEmbeddedMediaURLs?: (embeddedMediaURLs: string[]) => void,
        // setIsUploadingMedia?: (isUploadingMedia: boolean) => void,
        placeholder?: string,
        sx?: SxProps<Theme>,
        alternativeFocus?: boolean,
        onBlur?: () => void,
        onFocus?: () => void,
        isActivated?: boolean,
        focusOnMount?: boolean,
        disabled?: boolean,
    }
) {
    // const classes = useMarkdownStyles()
    const [isFocused, setIsFocused] = useState(false)
    const [wasFocused, setWasFocused] = useState(false)

    const [error, setError] = useState<null | string>(null)

    const paddingSide = isFocused ? "13px" : "14px"; // border will grow by 1px when focused
    const theme = useTheme()

    const [markdownForRenderer, setMarkdownForRenderer] = useState(markdown)
    const updateMarkdownForRenderer = useMemo(() => debounce((text) => setMarkdownForRenderer(text), 300), [])

    const updateMarkdown = useCallback((value: string) => {
        if(maxMarkdownLength && value.length > maxMarkdownLength) return
        onChange(value)
        updateMarkdownForRenderer(value)
    }, [maxMarkdownLength, onChange, updateMarkdownForRenderer])

    useEffect(() => {
        if(markdown.length === 0) {
            setMarkdownForRenderer(markdown)
        }
    }, [markdown])

    const [embeddedMediaUrls, setEmbeddedMediaUrls] = useState<string[]>([])

    // TODO we could make things easier if we used box-sizing: border-box

    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (focusOnMount && textAreaRef.current) {
            textAreaRef.current.focus()
        }
    }, [focusOnMount])

    const compressImage = useCallback(async (file: File) => {
        return new Promise((resolve: (result: File | Blob) => void, reject) => {
            new Compressor(file, {
                quality: 0.6,
                // maxWidth: 1920,
                // minWidth: 400,

                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success(compressedFile) {
                    resolve(compressedFile)
                },
                error(err) {
                    reject(err)
                },
            });
        })
    }, [])


    const uploadPromise = useRef<Promise<any> | null>(null)


    const uploadImage = useCallback(async (file: File, imageId: string) => {

        if(uploadPromise.current !== null) {
            await uploadPromise.current
        }

        if (!uploadDirectory) throw Error("upload directory not defined")
        if (!file.type.includes("image")) throw Error("file is not an image")
        const fileExtension = imageMimeTypeToExtensionMap[file.type as imageMimeType]

        let processedFile: File | Blob = file
        if (fileExtension === "image/png" || fileExtension === "image/jpeg") {
            processedFile = await compressImage(file)
        }

        if (processedFile.size > MAX_IMAGE_SIZE) throw Error("processed file size exceeds 2mb")

        const storagePath = `${uploadDirectory}/${imageId}.${fileExtension}`

        log("uploading image to", storagePath)
        const storage = getStorage()
        const storageRef = ref(storage, storagePath)
        if (!firebaseAuth.currentUser) throw Error("not authenticated")
        const metadata = {
            contentType: file.type,
            customMetadata: {
                publisher: firebaseAuth.currentUser.uid,
            }
        }
        const upProm = uploadBytes(storageRef, file, metadata)
        uploadPromise.current = upProm
        return await upProm
    }, [compressImage, uploadDirectory])


    const handleFileInput = useCallback(async (e: ClipboardEvent<HTMLTextAreaElement> | DragEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>) => {

        // Only enable image upload if uploadDirectory is defined
        if (!uploadDirectory) return

        // get file list from event
        const files = "clipboardData" in e ? e.clipboardData.files : // e is ClipboardEvent
            "dataTransfer" in e ? e.dataTransfer.files : // e is DragEvent
                e.target.files // e is ChangeEvent<HTMLInputElement>

        // files as returned by ChangeEvent<HTMLInputElement> can be null
        // and files length can be zero if we paste text
        if (!files || files.length === 0) return

        // prevent default so that no text is pasted
        e.preventDefault()

        // convert to list for convenience
        const fileList: File[] = []
        for (let i = 0; i < files.length; i++) fileList.push(files.item(i) as File)

        // make sure files are all images
        const isImage = (file: File) => file.type.split("/")[0] === "image"
        for (const file of fileList) {
            if (!isImage(file)) return setError("files must be images only")
        }

        let newMarkdown = markdownForRenderer
        let newEmbeddedMediaUrls = []

        // Get target position of image(s).
        // If we attach an image, image will be inserted at the end
        const dropIndex = e.currentTarget.selectionStart ?? newMarkdown.length // the position where the file was dropped

        // upload images
        const promises = []
        const imageIds = []
        for (const file of fileList) {
            let imageId = generateId()
            imageIds.push(imageId)
            promises.push(uploadImage(file, imageId))

            // Insert text placeholder
            newMarkdown = newMarkdown.substring(0, dropIndex).concat("![Image](Uploading " + imageId + " ...) \n" + newMarkdown.substring(dropIndex, newMarkdown.length))
        }
        const results = await Promise.allSettled(promises)
        let errorsOccurred = false
        for (let i = 0; i < results.length; i++) {
            const res = results[i]
            const imageId = imageIds[i]
            if (res.status === "fulfilled") {
                const mediaUrl = res.value.ref.fullPath
                newEmbeddedMediaUrls.push(mediaUrl)
                const imageArr = new Uint8Array(await fileList[i].arrayBuffer())
                if (onUploadMedia) onUploadMedia(mediaUrl)
                mediaBufferManager.addBuffer(mediaUrl, imageArr)
                newMarkdown = newMarkdown.replace("Uploading " + imageId + " ...", `discuna-storage://${mediaUrl}`)
            } else {
                errorsOccurred = true
                console.log(`upload of ${imageId} failed`)
                console.log(res)
                newMarkdown = newMarkdown.replace("![Image](Uploading " + imageId + " ...) \n", "")
            }
        }
        if (errorsOccurred) setError("Some files could not be uploaded. We currently only support images and GIFs no larger than 2mb.")

        // update markdown and media URLs
        setEmbeddedMediaUrls([...newEmbeddedMediaUrls, ...embeddedMediaUrls])
        updateMarkdown(newMarkdown)

    }, [onUploadMedia, uploadDirectory, markdownForRenderer, embeddedMediaUrls, updateMarkdown, uploadImage])


    return <>
        <Column
            sx={{
                ...(isFocused ? editorIsFocusedSxStyle(theme) : editorIsNotFocusedSxStyle(theme)),
                ...sx,
                borderRadius: "4px",
                overflow: "hidden",
                flexGrow: 1,
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
        >
            {
                wasFocused && isActivated &&
                <Row mainAxisAlignment={"stretch"}
                     crossAxisAlignment={"center"}
                     sx={{
                         paddingTop: isFocused ? "3px" : "4px",
                         paddingBottom: "4px",
                         paddingRight: paddingSide,
                         paddingLeft: paddingSide,
                         position: "relative"
                     }}>

                    <Box sx={{
                        flexGrow: 1,
                        position: "relative",
                        // height: "100%",

                    }}>
                        <Box
                            className={"qanda-hideScrollbar"}
                            sx={{
                                maxWidth: "100%",
                                position: "absolute",
                                overflowX: "auto",
                                transform: "translateY(-50%)"
                            }}
                        >
                            <Row
                                sx={{
                                    // overflowX: "auto",
                                    whiteSpace: "nowrap",
                                    fontSize: "12px",
                                    pointerEvents: "none",
                                    // position: "relative",
                                    maxWidth: "100%",
                                    // position: "absolute",
                                    // transform: "translateY(-50%)"
                                }}
                                mainAxisAlignment={"start"}
                                crossAxisAlignment={"center"}>

                                **bold** <ArrowRightRounded/> <b>bold</b>
                                <Divider style={{margin: "3px 12px"}} orientation="vertical" flexItem/>
                                *italic* <ArrowRightRounded/> <i>italic</i>
                                <Divider style={{margin: "3px 12px"}} orientation="vertical" flexItem/>
                                $x^2$ <ArrowRightRounded/> <span
                                dangerouslySetInnerHTML={{__html: katex.renderToString("x^2")}}/>
                                <Divider style={{margin: "3px 12px"}} orientation="vertical" flexItem/>
                                [link](url) <ArrowRightRounded/> <a style={{pointerEvents: "none", color: "black"}}
                                                                    href={"https://qanda.link/"}
                                                                    onClick={() => false}>link</a>
                            </Row>
                        </Box>
                    </Box>

                    <Row
                        className={"qanda-hideScrollbar"}
                        sx={{
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            // pointerEvents: "none",
                            position: "relative"
                        }}
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"center"}
                    >
                        {
                            uploadDirectory &&
                            <IconButton
                                edge={"end"}
                                size={"small"}
                                sx={{
                                    zIndex: 2,
                                    mr: 0.5,
                                }}
                                component={"label"}
                            >
                                <input
                                    type="file"
                                    accept={"image/*"}
                                    multiple={true}
                                    hidden
                                    // Note: Can't attach the same file twice right after another
                                    onChange={handleFileInput}
                                />
                                <AddPhotoAlternateOutlinedIcon sx={{height: 21.818181818181}}/>
                            </IconButton>
                        }

                        <IconButton
                            // edge={"end"}
                            size={"small"}
                            style={{
                                zIndex: 2
                            }}
                            onClick={() => window.open("https://guides.github.com/features/mastering-markdown/", "_blank")}
                        >
                            <HelpOutlineRoundedIcon/>
                        </IconButton>
                    </Row>

                </Row>
            }

            <Box
                sx={{
                    paddingTop: (wasFocused && isActivated) ? "3.5px" : "18.5px",
                    paddingBottom: (markdown.length === 0 && isFocused) ? "17.5px" : "18.5px",
                    paddingRight: paddingSide,
                    paddingLeft: paddingSide,
                }}
            >
                <TextareaAutosize
                    disabled={disabled}
                    ref={textAreaRef}
                    onChange={(e) => {
                        updateMarkdown(e.currentTarget.value)
                    }}

                    onPaste={handleFileInput}
                    onDrop={handleFileInput}
                    onKeyDown={(e) => {
                        if (e.key === "Tab") {
                            e.preventDefault()
                            const start = e.currentTarget.selectionStart
                            const end = e.currentTarget.selectionEnd
                            const value = e.currentTarget.value
                            e.currentTarget.value = value.substring(0, start) + "    " + value.substring(end)
                            e.currentTarget.selectionStart = e.currentTarget.selectionEnd = start + 4
                            updateMarkdown(e.currentTarget.value)
                        }
                    }}

                    value={markdown}
                    onFocus={() => {
                        setIsFocused(true)
                        setWasFocused(true)
                        if (onFocus) onFocus()
                    }}
                    onBlur={() => {
                        setIsFocused(false)
                        if (onBlur) onBlur()
                    }}
                    style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "4px",
                        // flexGrow: 1,
                        width: "100%",
                        boxSizing: "border-box",
                        // padding: body.length === 0 ?
                        //     (isFocused ? "18.5px 13px 17.5" : "18.5px 14px") :
                        //     (isFocused ? "18.5px 13px 18.5px" : "18.5px 14px"),
                        resize: "none",
                        // ...(isActivated !== undefined && !isActivated) && {
                        //     // TODO for some reason, textAreaAutoSize computes a wrong height once focused...
                        //     height: "min-content"
                        // },
                        // position: "static",
                        // height: "min-content",
                        // backgroundColor: "inherit",
                        // height: "max-content",
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        backgroundColor: "inherit"
                    }}
                    // minRows={1}
                    placeholder={placeholder ?? "Discuna Markdown Editor"}
                />
            </Box>
            {
                markdown.length > 0 &&
                <>
                    <Divider/>
                    <Column
                        sx={{
                            paddingTop: "18.5px",
                            paddingBottom: isFocused ? "17.5px" : "18.5px",
                            paddingRight: paddingSide,
                            paddingLeft: paddingSide,
                            flexGrow: 1,
                            // maxHeight: "40vh",
                            // overflow: "auto"
                        }}
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"start"}>
                        <MarkdownRenderer markdown={markdownForRenderer}/>
                    </Column>
                </>
            }
        </Column>
        <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={error != null}
                  autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error" sx={{width: '100%'}}>
                {error}
            </Alert>
        </Snackbar>
    </>
}


// TODO remove => add prop to main MarkdownEditor
export function MarkdownEditorReply(props: {
    markdown: string,
    onChange: (value: string) => void,
    alternativeFocus?: boolean
}) {
    // const classes = useMarkdownStyles()
    const [isFocused, setIsFocused] = useState(false)
    const paddingSide = isFocused ? "13px" : "14px"; // border will grow by 1px when focused


    const [body, setBody] = useState(props.markdown)
    const theme = useTheme()

    return (
        <Column
            sx={isFocused ? {
                ...editorIsFocusedSxStyle(theme),
                borderRadius: "4px",
                overflow: "hidden",
                flexGrow: 1,
            } : {
                ...editorIsNotFocusedSxStyle(theme),
                borderRadius: "4px",
                overflow: "hidden",
                flexGrow: 1,
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
        >
            <TextareaAutosize
                onChange={(e) => {
                    setBody(e.currentTarget.value)
                    props.onChange(e.currentTarget.value)
                }}
                value={props.markdown}
                onFocus={() => {
                    setIsFocused(true)
                }}
                onBlur={() => {
                    setIsFocused(false)
                }}
                style={{
                    border: "none",
                    outline: "none",
                    borderRadius: "4px",
                    paddingTop: isFocused ? "17.5px" : "18.5px", // no row on top
                    paddingBottom: (body.length === 0 && isFocused) ? "17.5px" : "18.5px",
                    paddingRight: paddingSide,
                    paddingLeft: paddingSide,
                    resize: "none",
                    fontFamily: "inherit",
                    fontSize: "inherit"
                }}
                minRows={1}
                placeholder={"Qanda Markdown Editor"}/>
            {
                props.markdown.length > 0 &&
                <>
                    <CustomDivider>
                        <b style={{zIndex: 10, color: "#515A5A", fontSize: "12px", padding: "0px 10px"}}>PREVIEW</b>
                    </CustomDivider>
                    <Column
                        sx={{
                            paddingTop: "18.5px",
                            paddingBottom: isFocused ? "17.5px" : "18.5px",
                            paddingRight: paddingSide,
                            paddingLeft: paddingSide,
                            flexGrow: 1
                        }}
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"start"}>
                        <MarkdownRenderer markdown={props.markdown}/>
                    </Column>
                </>
            }
        </Column>
    )
}
