import {compile, TypeOf, v} from "suretype";
import {idSchema} from "./utils/schema";
import {CommentFirestoreData, MAX_CHAT_COMMENT_BODY_LENGTH} from "./comments";

// ==========================
// constants
// ==========================

export const MAX_CHAT_THREAD_TITLE_LENGTH = 400

// ==========================
// data types
// ==========================

export interface ThreadFirestoreData {
    details: {
        title: string,
        subscribers: string[],
        comments: Record<string, CommentFirestoreData>
    }

    // sort
    createdOn: number,

    // filter
    solved?: boolean
}

// ==========================
// utils
// ==========================

export function createThreadFromArgs(
    member: { id: string, name: string },
    args: CreateChatThreadArgs,
    requiresAction: boolean
) {
    const createdOn = (new Date()).getTime()
    return {
        details: {
            title: args.title,
            subscribers: [member.id],
            comments: {
                [args.threadId]: {
                    authorId: member.id,
                    authorName: (!args.incognito) ? member.name : null,
                    body: args.body,
                    createdOn,
                    updatedOn: null,
                    reactions: {},
                    replyTo: null,
                }
            }
        },
        // sort
        createdOn,
        // filter
        ...(requiresAction) && {
            solved: false
        }
    } as ThreadFirestoreData
}


// ==========================
// action args
// ==========================

export const createChatThreadArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    title: v.string().maxLength(MAX_CHAT_THREAD_TITLE_LENGTH).required(),
    body: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
    incognito: v.boolean().required(),
})
export type CreateChatThreadArgs = TypeOf<typeof createChatThreadArgsSchema>
export const createChatThreadArgsValidator = compile(createChatThreadArgsSchema)

export const setThreadSolvedArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    solved: v.boolean().required()
})
export type SetThreadSolvedArgs = TypeOf<typeof setThreadSolvedArgsSchema>
export const setThreadSolvedArgsValidator = compile(setThreadSolvedArgsSchema)

export const setThreadBookmarkArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    bookmark: v.string().enum("", "interesting", "todo").required()
})
export type SetThreadBookmarkArgs = TypeOf<typeof setThreadBookmarkArgsSchema>
export const setThreadBookmarkArgsValidator = compile(setThreadBookmarkArgsSchema)

export const toggleThreadSubscriptionArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    isSubscribed: v.boolean().required()
})
export type ToggleThreadSubscriptionArgs = TypeOf<typeof toggleThreadSubscriptionArgsSchema>
export const toggleThreadSubscriptionArgsValidator = compile(toggleThreadSubscriptionArgsSchema)

export const updateThreadTitleArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    title: v.string().maxLength(MAX_CHAT_THREAD_TITLE_LENGTH).required(),
})
export type UpdateThreadTitleArgs = TypeOf<typeof updateThreadTitleArgsSchema>
export const updateThreadTitleArgsValidator = compile(updateThreadTitleArgsSchema)

export enum CommunityBookmarkType {
    solved = "solved",
    unsolved = "unsolved",
}
