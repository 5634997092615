import {idSchema} from "./utils/schema";
import {v, compile, TypeOf} from "suretype";


// ==========================
// constants
// ==========================

export const maxChannelNameLength = 200
export const maxChannelDescriptionLength = 2000


// ==========================
// types
// ==========================

export interface ChannelFirestoreData {
    details: {
        description: string | null,
        permissions: {
            read: {
                roles: string[],
                userIds: string[],
            } | null
            // write permission for writing root comments, everyone can answer
            write: {
                roles: string[],
                userIds: string[],
            } | null
        },
        commentsRequireAction: boolean
    },

    createdOn: number
    // // => enforce non-empty write permission, we can use different icon for announcement channel
    // type: "announcement" | "chat"
}


export const channelReferenceInfoSchemaSureType = v.object({
    id: idSchema.required(),
    communityId: idSchema.required(),
})
export const channelReferenceInfoValidator = compile(channelReferenceInfoSchemaSureType)
export type ChannelReferenceInfo = TypeOf<typeof channelReferenceInfoSchemaSureType>


// ==========================
// constants
// ==========================

export const chatChannelProtocol = "chatchannel" as const
export const announcementChannelProtocol = "announcementchannel" as const


// ==========================
// action args
// ==========================

export const createChatChannelArgsSchema = v.object({
    communityId: idSchema.required(),
    parentChannelId: v.anyOf([idSchema, v.string().const("root")]).required(),
    channelId: idSchema.required(),
    name: v.string().minLength(1).maxLength(maxChannelNameLength).required(),
    details: v.object({
        description: v.string().maxLength(maxChannelDescriptionLength).required(),
        permissions: v.object({
            write: v.object({
                roles: v.array(v.string()).required(),
                userIds: v.array(v.string()).required(),
            }).required(),
            read: v.object({
                roles: v.array(v.string()).required(),
                userIds: v.array(v.string()).required(),
            }).required(),
        }).required(),
        commentsRequireAction: v.boolean().required(),
    }).required()
})

export const createChatChannelArgsValidator = compile(createChatChannelArgsSchema)
export type CreateChatChannelArgs = TypeOf<typeof createChatChannelArgsSchema>


export function createChatChannelFromArgs(args: CreateChatChannelArgs) {
    return {
        details: args.details,
    } as ChannelFirestoreData
}

export const updateChatChannelArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    description: v.string().maxLength(maxChannelDescriptionLength).required(),
})
export const updateChatChannelArgsValidator = compile(updateChatChannelArgsSchema)
export type UpdateChatChannelArgs = TypeOf<typeof updateChatChannelArgsSchema>




