import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Column, MotionBox, Row, RowMotion} from "../../../../common/motion_mui"
import {Box} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {ANIMATE_STROKE, STROKE_COLOR, STROKE_DIAMETER, STROKE_HOVER_SCALE, StrokeProps} from "./stroke";
import { motion } from "framer-motion";

export const Indent = React.forwardRef<HTMLDivElement, {
    isLastChild: boolean,
    item: ReactJSXElement,
    layoutId: string,
} & StrokeProps>(({
    isLastChild,
    item,
    layoutId,

    // stroke state
    strokeState,
    setStrokeState,
    applyStrokeState,

    // stroke events
    onStrokeClick,
    onStrokeHoverStart,
    onStrokeHoverEnd
}, ref) => {

    // NOTE scale applies to all strokes
    const strokeScale = applyStrokeState && strokeState === "hovered" ? STROKE_HOVER_SCALE : 1


    return (
        <RowMotion
            // layoutId={layoutId}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                flexGrow: 1,
                // overflow: "hidden"
            }}
        >
            <Column
                mainAxisAlignment={"stretch"}
                crossAxisAlignment={"start"}
                sx={{
                    minWidth: `${48}px`,
                }}
            >
                <MotionBox
                    // up stroke
                    layoutId={!ANIMATE_STROKE ? undefined : `top-${layoutId}`}
                    animate={{
                        scaleX: strokeScale
                    }}
                    sx={{
                        cursor: "pointer",
                        ml: `${4 * 8 - STROKE_DIAMETER / 2}px`,
                        width: STROKE_DIAMETER,
                        mt: "-1px",
                        height: "33px", // (2 [margin] + 2 [avatar radius]) * 8 + 1
                        bgcolor: STROKE_COLOR,
                    }}
                    onClick={() => {
                        setStrokeState(null)
                        if(onStrokeClick) onStrokeClick("indentTop")
                    }}
                    onMouseEnter={() => {
                        setStrokeState("hovered")
                        if(onStrokeHoverStart) onStrokeHoverStart("indentTop")
                    }}
                    onMouseLeave={() => {
                        setStrokeState(null)
                        if(onStrokeHoverEnd) onStrokeHoverEnd()
                    }}
                    onMouseDown={() => setStrokeState("clicked")}
                    // mouse up is only triggered if we are still hovering
                    onMouseUp={() => setStrokeState("hovered")}
                />
                <Box>
                    <MotionBox
                        // right stroke
                        layoutId={!ANIMATE_STROKE ? undefined : `right-${layoutId}`}
                        animate={{
                            scaleY: strokeScale
                        }}

                        sx={{
                            cursor: "pointer",
                            ml: `${4 * 8}px`,
                            mt: `${-STROKE_DIAMETER / 2}px`,
                            position: "absolute",
                            width: `${17}px`, // We add a pixel to fix weird render issue
                            height: STROKE_DIAMETER,
                            bgcolor: STROKE_COLOR,
                        }}
                        onClick={() => {
                            setStrokeState(null)
                            if(onStrokeClick) onStrokeClick("indentRight")
                        }}
                        onMouseEnter={() => {
                            setStrokeState("hovered")
                            if(onStrokeHoverStart) onStrokeHoverStart("indentRight")
                        }}
                        onMouseLeave={() => {
                            setStrokeState(null)
                            if(onStrokeHoverEnd) onStrokeHoverEnd()
                        }}
                        onMouseDown={() => setStrokeState("clicked")}
                        // mouse up is only triggered if we are still hovering
                        onMouseUp={() => setStrokeState("hovered")}
                    />
                    <MotionBox
                        // joint stroke (circle combining top, right, and bottom stroke)
                        layoutId={!ANIMATE_STROKE ? undefined : `joint-${layoutId}`}
                        animate={{
                            scale: strokeScale,
                        }}
                        sx={{
                            cursor: "pointer",
                            ml: `${4 * 8 - STROKE_DIAMETER / 2}px`,
                            mt: `${-STROKE_DIAMETER / 2}px`,
                            position: "absolute",
                            width: STROKE_DIAMETER,
                            height: STROKE_DIAMETER,
                            bgcolor: STROKE_COLOR,
                            borderRadius: `50%`
                        }}
                        onClick={() => {
                            setStrokeState(null)
                            if(onStrokeClick) onStrokeClick("indentJoint")
                        }}
                        onMouseEnter={() => {
                            setStrokeState("hovered")
                            if(onStrokeHoverStart) onStrokeHoverStart("indentJoint")
                        }}
                        onMouseLeave={() => {
                            setStrokeState(null)
                            if(onStrokeHoverEnd) onStrokeHoverEnd()
                        }}
                        onMouseDown={() => setStrokeState("clicked")}
                        // mouse up is only triggered if we are still hovering
                        onMouseUp={() => setStrokeState("hovered")}
                    />
                </Box>
                {
                    !isLastChild &&
                    <MotionBox
                        // bottom stroke
                        layoutId={!ANIMATE_STROKE ? undefined : `bottom-${layoutId}`}
                        animate={{
                            scaleX: strokeScale
                        }}
                        sx={{
                            cursor: "pointer",
                            ml: `${4 * 8 - STROKE_DIAMETER / 2}px`,
                            width: STROKE_DIAMETER,
                            flexGrow: 1,
                            bgcolor: STROKE_COLOR
                        }}
                        onClick={() => {
                            setStrokeState(null)
                            if(onStrokeClick) onStrokeClick("indentBottom")
                        }}
                        onMouseEnter={() => {
                            setStrokeState("hovered")
                            if(onStrokeHoverStart) onStrokeHoverStart("indentBottom")
                        }}
                        onMouseLeave={() => {
                            setStrokeState(null)
                            if(onStrokeHoverEnd) onStrokeHoverEnd()
                        }}
                        onMouseDown={() => setStrokeState("clicked")}
                        // mouse up is only triggered if we are still hovering
                        onMouseUp={() => setStrokeState("hovered")}
                    />
                }
            </Column>
            {item}
        </RowMotion>
    )
})

export const IndentMotion = motion(Indent)
