import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Channel} from "./reduxTypes";


const channelSlice = createSlice({
    name: 'channel',
    initialState: {
        channel: null as null | Channel
    },
    reducers: {
        setChannel(state, action: PayloadAction<Channel | null>) {
            state.channel = action.payload
        },
    }
})

export const {
    setChannel
} = channelSlice.actions

export const channelReducer = channelSlice.reducer
