import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {RootState} from "../store";
import {Member} from "./reduxTypes";


const memberEntityAdapter = createEntityAdapter<Member>()

const memberSlice = createSlice({
    name: "members",
    initialState: memberEntityAdapter.getInitialState({}),
    reducers: {
        addMember: memberEntityAdapter.addOne,
        upsertMember: memberEntityAdapter.upsertOne,
        updateMember: memberEntityAdapter.updateOne,
        removeMember: memberEntityAdapter.removeOne,
        removeAllMembers: memberEntityAdapter.removeAll,
        setAllMembers: memberEntityAdapter.setAll
    }
})

export const {
    addMember,
    upsertMember,
    updateMember,
    removeMember,
    removeAllMembers,
    setAllMembers
} = memberSlice.actions

export const memberReducer = memberSlice.reducer

export const {
    selectAll: selectAllMembers,
    selectById: selectMemberById,
    selectIds: selectMemberIds,
} = memberEntityAdapter.getSelectors<RootState>(state => state.members)

