import {useDispatch, useSelector} from "react-redux";
import useMemoizedState from "./useMemoizedState";
import {removeMember, upsertMember} from "../slices/memberSlice";
import {RootState} from "../store";
import {useEffect} from "react";
import {firestoreManager} from "../../firebase/queryManager";
import {Member} from "../slices/reduxTypes";
import { MemberFirestoreData } from "../../shared";
import {getFirestoreMemberRef} from "../../firebase/firestoreReferences";

export function useMember({memberId, communityId}: {
    memberId: string,
    communityId: string
}) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)


    // fetch community and community member data
    useEffect(() => {
        if (auth.isSignedIn) {
            const memberRef = getFirestoreMemberRef({communityId, memberId})
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(memberRef, (doc) => {
                setIsLoading(false)
                if (doc.exists()) {
                    const member = {
                        id: doc.id,
                        isSynced: true,
                        ...(doc.data() as MemberFirestoreData)
                    } as Member
                    dispatch(upsertMember(member))
                } else {
                    dispatch(removeMember(memberId))
                }
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [memberId, communityId, dispatch, setIsLoading, auth])
    return isLoading
}
