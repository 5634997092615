import {useCallback} from "react";
import {deleteObject, getStorage, ref} from "firebase/storage";
import {extractMediaUrlFromRegexResult, markdownEditorImageRegex} from "../../../shared";


// uploadedMediaUrls: string[]
export function useMediaManager() {

    // TODO why do we need this ref?
    // const uploadedMediaUrlsRef = useRef<string[]>([])
    // useEffect(() => {
    //     uploadedMediaUrlsRef.current = uploadedMediaUrls ?? []
    // }, [uploadedMediaUrls])

    const deleteMedia = useCallback((mediaUrl: string) => {
        const storage = getStorage();
        const mediaRef = ref(storage, mediaUrl);
        deleteObject(mediaRef).then(() => {
            console.log("Media deletion success", mediaUrl)
        }).catch((error) => {
            console.log("Media deletion error", mediaUrl, error.message)
        });
    }, [])

    // delete unused uploaded media when submit
    // and reset uploadedMedia
    const deleteUnusedMedia = useCallback((markdown: string, uploadedMediaUrls: string[]) => {

        // get all media URLs from body
        const results = markdown.match(markdownEditorImageRegex) ?? []
        const usedMediaUrls = [] as string[]
        results.forEach((res) => {
            const mediaUrl = extractMediaUrlFromRegexResult(res)
            usedMediaUrls.push(mediaUrl)
        })
        const usedMediaUrlsSet = new Set(usedMediaUrls)

        // delete all unused media URLs
        // NOTE Media that was present in the previous version but has been removed for the new version
        // is deleted on the server side to avoid memory leakage.
        // (Media once used has metadata that indicates that this media is in usage. So if cleanup fails on the
        // client side, the unused media won't be removed by cleanup jobs as well leading to memory leakage)
        const unusedMediaUrls = uploadedMediaUrls.filter((url) => !usedMediaUrlsSet.has(url))

        unusedMediaUrls.forEach((url) => {
            deleteMedia(url)
        })

        // reset uploadedMedia
        // NOTE deleteUnusedMedia is only called on submit
        // uploadedMediaUrlsRef.current = []
    }, [deleteMedia])

    // delete all uploaded media when cancel or components unmounts before submission
    // and reset uploadedMedia
    const deleteAllUploadedMedia = useCallback((uploadedMediaUrls: string[]) => {
        uploadedMediaUrls.forEach((mediaUrl) => {
            deleteMedia(mediaUrl)
        })
        // uploadedMediaUrlsRef.current = []
    }, [deleteMedia])

    // TODO replace with function call
    // delete all uploaded media when component unmounts
    // NOTE: When calling deleteUnusedMedia, the media that was present in the previous
    // version but has been removed for the new version
    // useEffect(() => {
    //     return deleteAllUploadedMedia
    // }, [deleteAllUploadedMedia])

    return [deleteUnusedMedia, deleteAllUploadedMedia] as const
}
