import {useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {useAuth} from "react-oidc-context";
import {signInWithCustomToken} from "firebase/auth";
import {API_TOKEN_ENDPOINT} from "../appEnv";
import {firebaseAuth} from "../../firebase/firebase";


export function OAuthCallback() {
    const navigate = useNavigate()

    const oauth = useAuth();

    const redirect = useCallback(() => {
        const targetURL = window.localStorage.getItem("auth/targetURL")
        let targetRelativePath = "/"
        if (targetURL) {
            try {
                const targetURLObj = new URL(targetURL)
                targetRelativePath = targetURLObj.pathname + targetURLObj.search
            } catch (e) {
                console.error("target URL is invalid")
            }
        }
        window.localStorage.removeItem("auth/targetURL")
        navigate(targetRelativePath)
    }, [navigate])

    const firebaseSignIn = useCallback(async (token: string) => {

        // exchange token for firebase token
        // Use the access token to call the firebaseCustomToken endpoint.
        const firebaseTokenResponse = await fetch(`${API_TOKEN_ENDPOINT}/firebase/token`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        // TODO only convert to json when status ok
        const firebaseTokenResponseJSON = await firebaseTokenResponse.json();
        const firebaseToken = firebaseTokenResponseJSON.firebaseToken

        // Use the Firebase custom token to authenticate with Firebase.
        // https://firebase.google.com/docs/auth/web/custom-auth
        const firebaseUser = (await signInWithCustomToken(firebaseAuth, firebaseToken)).user
    }, [])

    useEffect(() => {
        if(firebaseAuth.currentUser) {
            redirect()
        } else if (!oauth.isLoading) {
            if(oauth.isAuthenticated && oauth.user) {
                const token = oauth.user.access_token
                firebaseSignIn(token).then(redirect)
            } else {
                // try again
                // redirect()
            }
        }
    }, [firebaseSignIn, oauth, redirect])
    return <>OAuth Callback</>
}
