export function mergeParticipants (a: Record<string, {latestMessageAt: number, name: string}>, b: Record<string, {latestMessageAt: number, name: string}>) {
    // merges b into a
    for(const userId of Object.keys(b)) {
        const prevLastMessageAt = a[userId]?.latestMessageAt??0
        a[userId] = b[userId]
        if(prevLastMessageAt > a[userId].latestMessageAt) {
            a[userId].latestMessageAt = prevLastMessageAt
        }
    }
}

export function getTimeString(timestamp: number) {
    const date = new Date(timestamp)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
}
