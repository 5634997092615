import {Box} from "@mui/material";
import React from "react";
import {STROKE_COLOR, STROKE_DIAMETER, STROKE_HOVER_SCALE, StrokeProps, strokeStates} from "./utils/stroke";
import {Column, MotionBox} from "../../../common/motion_mui";

export function ChatItemStrokes(
    {
        replyTo,
        chatCommentId,
        isSingleChild,

        // stroke state
        strokeState,
        setStrokeState,
        applyStrokeState,
        applyStrokeStateBefore,
        applyStrokeStateAfter,

        // stroke events
        onStrokeClick,
        onStrokeHoverStart,
        onStrokeHoverEnd
    }: {
        // if replyTo === null => no top and left stroke
        replyTo: string | null,

        // if chatCommentId is set => no top stroke but a left stroke
        chatCommentId?: string,

        // if isSingleChild, no left stroke
        isSingleChild: boolean,
    } & StrokeProps
) {

    const strokeScaleBefore = (applyStrokeState || applyStrokeStateBefore) && strokeState === "hovered" ?
        STROKE_HOVER_SCALE : 1
    const strokeScaleAfter = (applyStrokeState || applyStrokeStateAfter) && strokeState === "hovered" ?
        STROKE_HOVER_SCALE : 1

    return (
        <Column
            mainAxisAlignment={"stretch"}
            crossAxisAlignment={"start"}
            sx={{
                minWidth: `${48}px`, // 6 * 8
                position: "absolute",
                height: "100%"
            }}
        >
            <MotionBox
                // up stroke
                // layoutId={`top-${replyTo??}`}
                animate={{
                    scaleX: strokeScaleBefore
                }}
                sx={{
                    cursor: "pointer",
                    width: STROKE_DIAMETER,
                    height: "33px", // (2 [margin] + 2 [avatar radius]) * 8
                    mt: "-17px",
                    ml: `${2 * 8 - STROKE_DIAMETER / 2}px`,
                    bgcolor: STROKE_COLOR,
                    visibility: replyTo === null ? "visible" : !isSingleChild ? "hidden" : "visible"
                }}
                onClick={() => {
                    setStrokeState(null)
                    if (onStrokeClick) onStrokeClick("chatItemBefore")
                }}
                onMouseEnter={() => {
                    setStrokeState("hovered")
                    if (onStrokeHoverStart) onStrokeHoverStart("chatItemBefore")
                }}
                onMouseLeave={() => {
                    setStrokeState(null)
                    if (onStrokeHoverEnd) onStrokeHoverEnd()
                }}
                onMouseDown={() => setStrokeState("clicked")}
                // mouse up is only triggered if we are still hovering
                onMouseUp={() => setStrokeState("hovered")}
            />
            <Box>
                <MotionBox
                    // left stroke
                    animate={{
                        scaleY: strokeScaleBefore
                    }}
                    sx={{
                        cursor: "pointer",
                        ml: -2,
                        mt: `${-STROKE_DIAMETER / 2}px`,
                        position: "absolute",
                        width: `${16 + STROKE_DIAMETER}px`, // (2 [margin] + 2 [avatar radius]) * 8
                        height: STROKE_DIAMETER,
                        bgcolor: STROKE_COLOR,
                        visibility: replyTo === null || isSingleChild ? "hidden" : "visible"
                    }}
                    onClick={() => {
                        setStrokeState(null)
                        if (onStrokeClick) onStrokeClick("chatItemBefore")
                    }}
                    onMouseEnter={() => {
                        setStrokeState("hovered")
                        if (onStrokeHoverStart) onStrokeHoverStart("chatItemBefore")
                    }}
                    onMouseLeave={() => {
                        setStrokeState(null)
                        if (onStrokeHoverEnd) onStrokeHoverEnd()
                    }}
                    onMouseDown={() => setStrokeState("clicked")}
                    // mouse up is only triggered if we are still hovering
                    onMouseUp={() => setStrokeState("hovered")}
                />
            </Box>
            {
                chatCommentId &&
                <MotionBox
                    // bottom stroke
                    animate={{
                        scaleX: strokeScaleAfter
                    }}
                    sx={{
                        cursor: "pointer",
                        mb: -2,
                        ml: `${2 * 8 - STROKE_DIAMETER / 2}px`,
                        width: STROKE_DIAMETER,
                        flexGrow: 1,
                        bgcolor: STROKE_COLOR,
                    }}
                    onClick={() => {
                        setStrokeState(null)
                        if (onStrokeClick) onStrokeClick("chatItemAfter")
                    }}
                    onMouseEnter={() => {
                        setStrokeState("hovered")
                        if (onStrokeHoverStart) onStrokeHoverStart("chatItemAfter")
                    }}
                    onMouseLeave={() => {
                        setStrokeState(null)
                        if (onStrokeHoverEnd) onStrokeHoverEnd()
                    }}
                    onMouseDown={() => setStrokeState("clicked")}
                    // mouse up is only triggered if we are still hovering
                    onMouseUp={() => setStrokeState("hovered")}
                />
            }
        </Column>
    )
}
