import {compile, v} from "suretype"

// NOTE
export const idSchema = v.string().matches("^[A-Za-z0-9-_]+$").minLength(3).maxLength(600)
export const idNullableSchema = v.anyOf([idSchema, v.null()])
export const idValidator = compile(idSchema)

export const urlSchema = v.string().format("uri")
export const urlValidator = compile(urlSchema)

export const fileIdSchema = v.string().minLength(1).maxLength(300)
export const fingerprintValidator = compile(fileIdSchema)
