

export function cancelablePromise<T>(promise: Promise<T>) {
    const controller = new AbortController();

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise.then(
            val => resolve(val),
            error => reject(error)
        )
        controller.signal.addEventListener("abort", () => {
            reject("aborted")
        })
    })
    return [wrappedPromise, () => {controller.abort()}] as const
}
