import {useThread} from "../hooks/useThread";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ChatThread} from "../components/channel/chatThread";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {useMember} from "../hooks/useMember";
import {discunaCoreFrontendApiClient} from "../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {useMemberSecrets} from "../hooks/useMemberSecrets";

export function NotificationContext() {

    const {communityId, channelId, notificationId} = useParams<{communityId: string, channelId: string, notificationId: string}>()

    if(!communityId || !channelId || !notificationId) throw Error("communityId, channelId, or notificationId not found")

    const auth = useSelector((state: RootState) => state.auth)
    if(!auth.isSignedIn) throw Error("unauthenticated")

    const [highlight, setHighlight] = useState<string | null>(null)

    // fetch member
    const memberIsLoading = useMember({memberId: auth.uid, communityId})

    // fetch member secrets
    const memberSecretsIsLoading = useMemberSecrets({memberId: auth.uid, communityId})

    // fetch notification data
    const [notificationData, setNotificationData] = useState<null | {
        threadId: string,
        commentId?: string
    }>(null)

    useEffect(() => {
        discunaCoreFrontendApiClient.getNotificationData<{threadId: string, commentId?: string}>().then(data => {
            setNotificationData(data)
        }).catch((e) => {console.error("could not fetch notification data", e)})
    }, [])

    // fetch thread
    const threadIsLoading = useThread(communityId, channelId, notificationData?.threadId??null)

    // tell Discuna we are ready
    useEffect(() => {
        if(!memberIsLoading && notificationData && !threadIsLoading) {
            discunaCoreFrontendApiClient.pageIsReady().then(() => {
                setHighlight(notificationData.commentId??notificationData.threadId)
            })
        }
    }, [memberIsLoading, notificationData, threadIsLoading])

    // callbacks
    const getMediaFolderUrl = useCallback(() => {
        return `communities/${communityId}/channels/${channelId}`
    }, [channelId, communityId])

    if(!notificationData) return null
    return (
        <ChatThread
            sx={{
                pb: 2
            }}
            communityId={communityId}
            channelId={channelId}
            threadId={notificationData.threadId}
            getMediaFolderUrl={getMediaFolderUrl}
            highlight={highlight}
            setHighlight={setHighlight}
        />
    )
}
