import {TextField, Typography} from "@mui/material";
import {MarkdownEditor} from "../components/markdownEditor/markdownEditor";
import {Column} from "../components/common/motion_mui";
import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {createChatChannel, generateId} from "../chatAppApi";
import {discunaCoreFrontendApiClient} from "../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {discunaAppFrontendApiHost} from "../../discunaAppToolkit/discunaAppFrontendCom/discunaAppFrontendApiHost";
import {CheckboxWithTooltip} from "../components/common/checkboxWithTooltip";


export function Create() {

    const {communityId, channelId: parentId} = useParams()
    if (!communityId || !parentId) throw Error("url malformed")

    const [channelName, setChannelName] = useState("")
    const [channelDescription, setChannelDescription] = useState("")
    const [commentsRequireAction, setCommentsRequireAction] = useState(true)
    const [isAnnouncementChannel, setIsAnnouncementChannel] = useState(false)


    // tell discuna our page app has loaded
    useEffect(() => {
        discunaCoreFrontendApiClient.pageIsReady()
    }, [])

    // sync create channel disable state
    const disableCreateChannelButton = useRef(true)
    useEffect(() => {
        if (channelName.length >= 1 && disableCreateChannelButton.current) {
            disableCreateChannelButton.current = false
            discunaCoreFrontendApiClient.setDisableCreateChannelButton(false).catch((e) => {
                console.error(e)
            })
        } else if (channelName.length === 0 && !disableCreateChannelButton.current) {
            disableCreateChannelButton.current = true
            discunaCoreFrontendApiClient.setDisableCreateChannelButton(true).catch((e) => {
                console.error(e)
            })
        }
    }, [channelName])

    // listen to click event of create channel button
    useEffect(() => {
        return discunaAppFrontendApiHost.onCreateChannelClick(async () => {
            try {
                await createChatChannel({
                    communityId,
                    parentChannelId: parentId,
                    channelId: generateId(),
                    details: {
                        description: channelDescription,
                        permissions: {
                            write: {
                                roles: isAnnouncementChannel ? ["administrator", "moderator"] : [],
                                userIds: []
                            },
                            read: {
                                roles: [],
                                userIds: []
                            }
                        },
                        commentsRequireAction: commentsRequireAction,
                    },
                    name: channelName
                })
            } catch (e) {
                console.error("createChatChannel failed", e)
            }
        })
    }, [commentsRequireAction, channelDescription, channelName, communityId, parentId, isAnnouncementChannel])

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
            width: "100%"
        }}>
            <TextField
                value={channelName}
                label={"Channel name"}
                variant={"filled"}
                onChange={(e) => {
                    setChannelName(e.currentTarget.value)
                }}
            />
            <MarkdownEditor placeholder={"Channel description"} sx={{mt: 2}}
                            markdown={channelDescription}
                            onChange={(value) => setChannelDescription(value.slice(0, 2000))}/>

            <CheckboxWithTooltip
                sx={{alignSelf: "start", mt: 1}}
                isChecked={commentsRequireAction}
                setIsChecked={setCommentsRequireAction}
                label={"Ticket system"}
                tooltipTitle={"If enabled, threads are initially marked as unsolved and can be set to (un-)solved by the author or admin or moderator."}
            />

            <CheckboxWithTooltip
                sx={{alignSelf: "start"}}
                isChecked={isAnnouncementChannel}
                setIsChecked={setIsAnnouncementChannel}
                label={"Announcement system"}
                tooltipTitle={"If enabled, threads can only be posted by admins or moderators."}
            />
        </Column>
    )
}
