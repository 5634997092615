import {Column} from "../common/motion_mui";
import React, {useCallback, useMemo, useState} from "react";
import {useMediaManager} from "../markdownEditor/useMediaManager";
import {createChatThread, createTitle, generateId} from "../../chatAppApi";
import {useDispatch} from "react-redux";
import {MarkdownEditor} from "../markdownEditor/markdownEditor";
import {arraySavePush} from "../../../shared/utils/arrayOps";
import {TitleForm} from "../markdownEditor/titleForm";
import {EditorFooter} from "../markdownEditor/editorFooter";
import {MAX_CHAT_COMMENT_BODY_LENGTH, MAX_CHAT_THREAD_TITLE_LENGTH} from "../../../shared";


export function ThreadForm({channelId, communityId, getMediaFolderUrl}:{
    channelId: string,
    communityId: string,
    getMediaFolderUrl: () => string
}) {

    // states
    const [showFullEditor, setShowFullEditor] = useState(false)
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const dispatch = useDispatch()

    // callbacks
    const createTitleCallback = useCallback(async (text: string) => {
        const res = await createTitle({text})
        return {title: res.data.title}
    }, [])

    const [uploadedMedia, setUploadMedia] = useState<string[]>([])
    const resetStates = useCallback(() => {
        setBody("")
        setTitle("")
        setShowFullEditor(false)
        setUploadMedia([])
    }, [])

    const uploadFolder = useMemo(getMediaFolderUrl, [getMediaFolderUrl])

    const [deleteUnusedMedia, deleteAllUploadedMedia] = useMediaManager() // uploadedMedia

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                m: 2
            }}
        >
            <MarkdownEditor
                maxMarkdownLength={MAX_CHAT_COMMENT_BODY_LENGTH}
                focusOnMount={false}
                markdown={body}
                onChange={(value) => {
                    setBody(value)
                }}
                onFocus={() => setShowFullEditor(true)}
                isActivated={showFullEditor}
                placeholder={"Post a new comment"}
                uploadDirectory={uploadFolder}
                onUploadMedia={(url) => {
                    setUploadMedia(arraySavePush(uploadedMedia, url))
                }}
            />
            {
                showFullEditor &&
                <>
                    {
                        <TitleForm
                            maxTitleLength={MAX_CHAT_THREAD_TITLE_LENGTH}
                            label={"Thread title"}
                            sx={{mt: 1}}
                            title={title}
                            setTitle={setTitle}
                            createTitle={createTitleCallback}
                            titleSeedText={body}
                        />
                    }
                    <EditorFooter
                        sendMode={"post"}
                        onSend={(data) => {
                            deleteUnusedMedia(body, uploadedMedia)
                            dispatch(createChatThread({
                                communityId,
                                channelId,
                                threadId: generateId(),
                                title,
                                body,
                                incognito: !data.showName
                            }));
                            resetStates()
                        }}
                        onCancel={() => {
                            deleteAllUploadedMedia(uploadedMedia)
                            resetStates()
                        }}
                        sendDisabled={title.length === 0 || body.length === 0}
                    />
                </>
            }

        </Column>
    )
}
