import { isEqual } from 'lodash';
import {useCallback, useState} from 'react';

// https://stackoverflow.com/a/70712897/7446235
const useMemoizedState = <T>(initialValue: T): [T, (val: T) => void] => {
    const [state, _setState] = useState<T>(initialValue);

    // https://reactjs.org/docs/hooks-reference.html#functional-updates
    // """
    // If your update function returns the exact same value as the current state,
    // the subsequent rerender will be skipped completely.
    // """
    const setState = useCallback((newState: T) => {
        _setState((prev) => {
            if (!isEqual(newState, prev)) {
                return newState;
            } else {
                return prev;
            }
        });
    }, []);

    return [state, setState];
};

export default useMemoizedState;