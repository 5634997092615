import {firestoreManager} from "../firebase/queryManager";
import {createAsyncThunk} from "@reduxjs/toolkit";

import {
    httpsCallable
} from "@firebase/functions"
import {RootState} from "./store";
import {
    CreateChatChannelArgs,
    CreateChatCommentArgs, CreateTitleArgs,
    DeleteChatCommentArgs,
    ToggleChatCommentReactionArgs,
    UpdateChatCommentArgs
} from "../shared";
import {doc, collection, getFirestore} from "firebase/firestore";
import {functions} from "../firebase/firebase";
import {Member} from "./slices/reduxTypes";
import {
    CreateChatThreadArgs,
    UpdateChatChannelArgs,
    SetThreadBookmarkArgs,
    SetThreadSolvedArgs,
    ToggleThreadSubscriptionArgs,
    UpdateThreadTitleArgs
} from "../shared";

export function generateId() {
    const col = collection(getFirestore(), "someCollectionName")
    return doc(col).id
}

export function createCloudFunction<T, D = unknown>(cmd: string) {
    return async (args: T) => {
        const cloudFunction = httpsCallable(functions, "internalApi");
        return await firestoreManager.callCloudFunction(cloudFunction, {
            payload: args,
            cmd
        }) as {
            data: D
        }
    }
}

export function createCustomAsyncThunk<T, M = {}>(
    cmd: string,
    getPendingMeta?: (arg: { arg: T, requestId: string }, {
        getState,
        extra
    }: { getState: () => RootState, extra: any }) => M
) {
    return createAsyncThunk<unknown, T, {
        state: RootState,
        pendingMeta: M & { member?: Member }
    }>(
        `api/${cmd}`,
        async (args, thunkApi) => {
            const cloudFunction = httpsCallable(functions, "internalApi");
            const res = await firestoreManager.callCloudFunction(cloudFunction, {
                payload: args,
                cmd
            })
            return res.data
            // TODO handle error
            // if(!result.success) {
            //     console.log(`cloud function ${group}-${name} failed with code ${result.errorCode}: ${result.errorMessage}`)
            //     throw Error(`cloud function ${group}-${name} failed with code ${result.errorCode}: ${result.errorMessage}`)
            // }
        },
        {
            getPendingMeta: ({arg, requestId}, {getState, extra}) => {
                // NOTE we do not have to deal with multiple communities and multiple channels
                const {auth, members} = getState()
                const customMeta = getPendingMeta ?
                    getPendingMeta({arg, requestId}, {getState, extra}) :
                    undefined as M
                let member
                if (auth.isSignedIn) member = members.entities[auth.uid]
                return {
                    ...customMeta,
                    member
                }
            }
        }
        // {
        //     idGenerator: () => firestore.collection("someIrrelevantCollectionName").doc().id,
        // }
    )
}

export const createTitle = createCloudFunction<CreateTitleArgs, { title: string }>("createTitle")
export const createChatChannel = createCloudFunction<CreateChatChannelArgs>("createChatChannel")
export const updateChatChannel = createCloudFunction<UpdateChatChannelArgs>("updateChatChannel")

export const createChatThread = createCustomAsyncThunk<CreateChatThreadArgs, {requiresAction: boolean}>(
    "createChatThread",
    ({arg, requestId}, {getState, extra}) => {
        const requiresAction = getState().channel.channel?.details.commentsRequireAction ?? false
        return {requiresAction}
    })
// to avoid synchronization issues, we use a cloud function to set the thread as solved
export const setThreadSolved = createCloudFunction<SetThreadSolvedArgs>("setThreadSolved")
export const setThreadBookmark = createCloudFunction<SetThreadBookmarkArgs>("setThreadBookmark")
export const toggleThreadSubscription = createCustomAsyncThunk<ToggleThreadSubscriptionArgs>("toggleThreadSubscription")
export const updateThreadTitle = createCustomAsyncThunk<UpdateThreadTitleArgs>("updateThreadTitle")

export const createChatComment = createCustomAsyncThunk<CreateChatCommentArgs>("createChatComment")
export const deleteChatComment = createCustomAsyncThunk<DeleteChatCommentArgs>("deleteChatComment")
export const updateChatComment = createCustomAsyncThunk<UpdateChatCommentArgs>("updateChatComment")
export const toggleChatCommentReaction = createCustomAsyncThunk<ToggleChatCommentReactionArgs>("toggleChatCommentReaction")
