

export function extractMediaUrlFromRegexResult(regexResult: string) {
    const mediaUrlPrefix = "discuna-storage://"
    const urlStart = regexResult.indexOf(mediaUrlPrefix) + mediaUrlPrefix.length
    return regexResult.slice(urlStart, -1)
}

export const markdownEditorImageRegex =
    /!\[[^[\]]*]\(discuna-storage?:\/\/\S+\.(gif|jpeg|png|tif|wbmp|ico|jng|bmp|svg|webp)\)/g
export const imageExtensions = ["gif", "jpeg", "png", "tif", "wbmp", "ico", "jng", "bmp", "svg", "webp"] as const
export type imageExtension = (typeof imageExtensions)[number]
export const imageMimeTypeToExtensionMap = {
    "image/gif": "gif",
    "image/jpeg": "jpeg",
    "image/png": "png",
    "image/tiff": "tif",
    "image/vnd.wap.wbmp": "wbmp",
    "image/x-icon": "ico",
    "image/x-jng": "jng",
    "image/x-ms-bmp": "bmp",
    "image/svg+xml": "svg",
    "image/webp": "webp",
}
export type imageMimeType = keyof typeof imageMimeTypeToExtensionMap
