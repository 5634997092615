import {useDispatch} from "react-redux";
import useMemoizedState from "./useMemoizedState";
import {useEffect} from "react";
import {getFirestoreThreadRef} from "../../firebase/firestoreReferences";
import {firestoreManager} from "../../firebase/queryManager";
import {removeOneThread, upsertOneThread} from "../slices/chatThreadSlice";
import {ThreadFirestoreData} from "../../shared/thread";


export function useThread(
    communityId: string,
    channelId: string,
    threadId: string | null
) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(false)

    // fetch thread
    useEffect(() => {
        if(threadId) {
            const threadsDocRef = getFirestoreThreadRef({communityId, channelId, threadId})
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(
                threadsDocRef,
                (doc) => {
                    setIsLoading(false)
                    if (!doc.exists()) {
                        dispatch(removeOneThread(doc.id))
                    } else {
                        dispatch(upsertOneThread({id: doc.id, data: doc.data() as ThreadFirestoreData}))
                    }
                }
            )
            setIsLoading(isNew)
            return cancel
        }
    }, [channelId, communityId, dispatch, setIsLoading, threadId])
    return isLoading
}
