import {Button, ButtonProps, CircularProgress, Typography} from "@mui/material";
import {BackgroundShadow, Center} from "./motion_mui";


export function CustomLoadingButton(
    {
        children,
        isLoading,
        disabled,
        sx,
        error,
        ...props
    }: {
        isLoading?: boolean,
        error?: string | null
    } & ButtonProps
) {
    return (
        <>
            <Button
                {...props}
                disabled={isLoading || disabled}
                sx={{...sx, position: "relative", overflow: "hidden"}}
            >
                {
                    isLoading &&
                    <Center style={{position: "absolute"}}>
                        <BackgroundShadow color={"rgba(0, 0, 0, 0.25)"}/>
                        <CircularProgress size={24}/>
                    </Center>
                }
                {children}
            </Button>
            {error && <Typography variant={"body2"} sx={{color: "red"}}>{error}</Typography>}
        </>

    )
}
