export const STROKE_COLOR = "#E4F2FF" // "#E4EFF9" // "#D5E7F8" // "#E8F4FF" // lightGrey // "#E1EEFA" // "#5498EC"
export const STROKE_DIAMETER = 8
export const STROKE_HOVER_SCALE = 1.4

export type collapsedThreadData = {
    threadId: number,
    endAt: number // date of chatComment where thread ends
}

export const ANIMATE_STROKE = false



export interface StrokeProps {
    // stroke state
    strokeState: strokeStates,
    setStrokeState: (state: strokeStates) => void,
    applyStrokeState?: boolean,
    applyStrokeStateBefore?: boolean,
    applyStrokeStateAfter?: boolean,

    // stroke events
    onStrokeClick?: (branch: strokeBranches) => void,
    onStrokeHoverStart?: (branch: strokeBranches) => void,
    onStrokeHoverEnd?: () => void,
}
export type strokeBranches = "indentTop" | "indentRight" | "indentBottom" | "indentJoint" | "chatItemBefore" | "chatItemAfter"
export type strokeStates = "hovered" | "clicked" | null
