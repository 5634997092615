import {v4 as uuidv4} from "uuid";

export class MessageClient {
    messageHandler: (event: MessageEvent) => void
    waiting: Map<string, {
        resolve: (data: any) => void,
        targetOrigin: string
    }>
    config: {
        targetOrigin?: string,
        meta?: Record<string, any>
    }

    constructor(config?: Partial<{
        targetOrigin: string,
        meta: Record<string, any>
    }>) {
        this.config = config ?? {}
        this.waiting = new Map()
        this.messageHandler = async (event: MessageEvent) => {
            const data = event.data
            if (typeof data === "object" && "nonce" in data && "response" in data) {
                const waitEntry = this.waiting.get(data.nonce)
                if (waitEntry) {
                    waitEntry.resolve(data.response)
                }
                this.waiting.delete(data.nonce)
            }
        }
    }

    _send(
        action: {
            cmd: string,
            payload?: unknown
        },
        targetOrigin: string = this.config.targetOrigin ?? "*",
        targetWindow: Window = window.parent
    ) {
        return new Promise((resolve, reject) => {
            const nonce = uuidv4()
            targetWindow.postMessage({
                action,
                nonce,
                ...(this.config.meta) && {meta: this.config.meta}
            }, targetOrigin)
            // we always expect a response (could be just a status response)
            this.waiting.set(nonce, {targetOrigin, resolve})
        })
    }

    get(
        cmd: string,
        targetOrigin: string = this.config.targetOrigin ?? "*",
        targetWindow?: Window
    ): Promise<any> {
        return this._send({cmd}, targetOrigin, targetWindow)
    }

    post(
        action: {
            cmd: string,
            payload?: unknown,
        },
        targetOrigin: string = this.config.targetOrigin ?? "*",
        targetWindow?: Window
    ): Promise<any> {
        return this._send(action, targetOrigin, targetWindow)
    }

    start() {
        window.addEventListener("message", this.messageHandler)
    }

    stop() {
        window.removeEventListener("message", this.messageHandler)
    }
}
