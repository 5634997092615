import {Button, IconButton, Snackbar} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";

export function useUpdateSW() {

    console.log("useUpdateSW")

    // check for updates
    const [showUpdateAvailable, setShowUpdateAvailable] = useState(false)
    const onUpdateSW = useCallback(() => {
        navigator.serviceWorker.getRegistration().then((registration) => {
            registration?.waiting?.postMessage({type: "SKIP_WAITING"})
            window.location.reload()
        })
    }, [])

    const handleClose = () => {
        setShowUpdateAvailable(false)
    }

    useEffect(() => {
        const eventListener: EventListener = (e: Event) => {
            // console.log("swUpdated")
            setShowUpdateAvailable(true)
        }
        window.addEventListener(
            "swUpdated",
            eventListener
        )
        return () => {
            window.removeEventListener("swUpdated", eventListener)
        }
    }, [])

    useEffect(() => {
        navigator.serviceWorker.getRegistration().then((registration) => {
            const waiting = registration?.waiting
            if (waiting && waiting.state === "installed") {
                setShowUpdateAvailable(true)
            }
        })
    }, [])

    const action = (
        <>
            <Button color="secondary" size="small" onClick={onUpdateSW}>
                INSTALL
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </>
    );

    const snackbar = (
        <Snackbar
            open={showUpdateAvailable}
            onClose={handleClose}
            message="Update available"
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
    )

    return snackbar
}
