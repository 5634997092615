import {MessageServer} from "./messageServer";
import {isNotificationClickAction} from "./discunaAppFrontendApiArgs";
import {DISCUNA_ORIGIN} from "../../app/appEnv";


export class DiscunaAppFrontendApiHost {
    messageServer: MessageServer
    discunaCoreFrontendOrigin: string

    constructor() {
        this.messageServer = new MessageServer()
        this.messageServer.start()
        this.discunaCoreFrontendOrigin = DISCUNA_ORIGIN
    }

    onCreateChannelClick(eventHandler: () => void) {
        return this.messageServer.setCmdHandler(
            "createChannelClick",
            (event: MessageEvent) => eventHandler(),
            this.discunaCoreFrontendOrigin,
            window.parent
        )
    }

    onNotificationClick<T = any>(eventHandler: (args: { notificationId: string, data: T }) => void) {
        return this.messageServer.setCmdHandler(
            "notificationClick",
            (event) => {
                if (isNotificationClickAction(event.data.action)) {
                    const {notificationId, data} = event.data.action.payload
                    return eventHandler({notificationId, data: JSON.parse(data)})
                }
            },
            this.discunaCoreFrontendOrigin,
            window.parent
        )
    }
}

export const discunaAppFrontendApiHost = new DiscunaAppFrontendApiHost()
