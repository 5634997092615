import {ChatItemContainerMotion} from "./chatItemContainer";
import React, {useMemo} from "react";
import {ANIMATE_STROKE, STROKE_COLOR, StrokeProps} from "./utils/stroke";
import {Indent} from "./utils/indent";
import {AvatarButton} from "../../../common/avatarButton";
import {Avatar, Link, Typography} from "@mui/material";
import {CommentNode} from "../chatCommentContainer";
import {Column, Row} from "../../../common/motion_mui";
import {darkGrey, primaryColor, primaryLightBackground} from "../../../../constants";
import {getTimeString, mergeParticipants} from "../commentUtils";
import {Icon} from "@iconify/react";
import {MarkdownRenderer} from "../../../markdownEditor/markdownRenderer";

export function ExpandItem({onExpand, chatComment, indented, layoutId, collapsedThreadReply, ...strokeProps}: {
    onExpand: () => void,
    chatComment: CommentNode,
    layoutId: string,
    indented?: boolean,
    collapsedThreadReply?: CommentNode
} & StrokeProps) {

    // loading when we click on expand
    // const [loading, setLoading] = useState(false)

    const [sortedParticipants, totalNumReplies, latestMessage] = useMemo(() => {
        const sortedParticipants = [] as {
            id: string,
            name: string,
            latestMessageAt: number,
        }[]
        let participants = {}
        let totalNumReplies = 0
        let latestMessage = null
        console.log("sortedParticipants", chatComment, collapsedThreadReply)
        if(!collapsedThreadReply || chatComment.children.length === 1) {
            participants = chatComment.participants
            totalNumReplies = chatComment.totalChildCount
            latestMessage = chatComment.latestMessage
        } else {
            for(const replyNode of chatComment.children) {
                if(replyNode.id !== collapsedThreadReply.id) {
                    mergeParticipants(participants, replyNode.participants)
                    if(replyNode.authorName && replyNode.body) {
                        mergeParticipants(participants, {
                            [replyNode.authorId]: {
                                name: replyNode.authorName,
                                latestMessageAt: replyNode.createdOn,
                            }
                        })
                    }
                    totalNumReplies += 1 + replyNode.totalChildCount
                    if(replyNode.latestMessage && (!latestMessage || replyNode.latestMessage.createdOn > latestMessage.createdOn)) {
                        latestMessage = replyNode.latestMessage
                    }
                    if(replyNode.body && (!latestMessage || replyNode.createdOn > latestMessage.createdOn)) {
                        latestMessage = replyNode
                    }
                }
            }
        }
        for(const userId in participants) {
            sortedParticipants.push({id: userId, ...chatComment.participants[userId]})
        }
        sortedParticipants.sort((a, b) => b.latestMessageAt - a.latestMessageAt)
        return [sortedParticipants, totalNumReplies, latestMessage]
    }, [chatComment, collapsedThreadReply])

    // display participants & latest message
    const item = useMemo(() => (
        <ChatItemContainerMotion
            layoutId={!ANIMATE_STROKE ? undefined : layoutId}
            avatar={
                <AvatarButton
                    onClick={onExpand}
                >
                    +
                </AvatarButton>
            }
            item={
                <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                    width: "100%"
                }}>
                    <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"} className={"hideScrollbar"} sx={{
                        maxWidth: "100%",
                        overflowX: "auto",
                    }}>
                        <Typography variant={"body1"} sx={{minWidth: "fit-content"}}>
                            <Link underline={"hover"} sx={{cursor: "pointer"}} color={"inherit"} onClick={onExpand}>{totalNumReplies} {totalNumReplies === 1 ? "reply" : "replies"}</Link> by
                        </Typography>
                        {
                            sortedParticipants.map((participant, index) => {
                                return (
                                    <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"} key={participant.id} sx={{
                                        ml: 1,
                                        minWidth: "fit-content"
                                    }}>
                                        <Row
                                            mainAxisAlignment={"start"}
                                            crossAxisAlignment={"center"}
                                            sx={{
                                                bgcolor: primaryLightBackground,
                                                borderRadius: "24px",
                                            }}
                                        >
                                            <Avatar sx={{
                                                width: 24,
                                                height: 24,
                                                bgcolor: primaryColor
                                            }}>
                                                {participant.id === "incognito" ?
                                                    <Icon icon="mdi:incognito" height={16}/> :
                                                    <Typography variant={"body1"}>
                                                        {participant.name[0]}
                                                    </Typography>
                                                }
                                            </Avatar>
                                            <Typography variant={"body1"} sx={{px: 1}}>
                                                {participant.name}
                                            </Typography>
                                        </Row>
                                        {/*{*/}
                                        {/*    index < sortedParticipants.length - 1 && (*/}
                                        {/*        <Typography variant={"body1"}>*/}
                                        {/*            ,*/}
                                        {/*        </Typography>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                    </Row>
                                )
                            })
                        }
                    </Row>
                    {
                        latestMessage && (
                            <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                                mt: 1
                            }}>
                                <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                                    <Typography variant={"body1"} sx={{whiteSpace: "nowrap"}}>
                                        <b>{latestMessage.authorName??"Incognito"}</b>
                                    </Typography>
                                    <Typography variant={"body1"} sx={{
                                        color: darkGrey,
                                        ml: 1,
                                        whiteSpace: "nowrap"
                                    }}>
                                        {getTimeString(latestMessage.createdOn)}
                                        {" (latest reply)"}
                                    </Typography>
                                </Row>
                                <MarkdownRenderer markdown={latestMessage.body??""}/>
                            </Column>
                        )
                    }
                </Column>

                // <Button
                //     size={"small"}
                //     sx={{
                //         ml: -1,
                //         px: 1,
                //         borderRadius: 0,
                //         alignSelf: "start"
                //     }}
                //     onClick={onExpand}
                // >
                //     Expand
                // </Button>
            }
            replyTo={chatComment.id}
            isSingleChild={!indented}
            {...strokeProps}
        />
    ), [chatComment.id, indented, latestMessage, layoutId, onExpand, sortedParticipants, strokeProps, totalNumReplies])

    if (!indented) return item
    return (
        <Indent
            layoutId={layoutId}
            isLastChild={false}
            item={item}
            {...strokeProps}
        />
    )
}
