import {Box, Typography} from "@mui/material";
import {useState} from "react";
import useDimensions from "react-cool-dimensions";
import {darkGrey} from "../../constants";
import {Row} from "./motion_mui";


export function ShowMore({maxHeight, children, bgcolor}: React.PropsWithChildren<{
    maxHeight: number,
    bgcolor: string,
}>) {
    const [showMore, setShowMore] = useState(false)
    const dims = useDimensions()

    const enabled = dims.height > maxHeight

    const expandedMaxHeight = maxHeight + 24

    return (
        <Box sx={{position: "relative"}}>
            <Box
                sx={{
                    ...(!showMore) && {
                        maxHeight: expandedMaxHeight,
                        overflow: "hidden",
                    }
                }}
            >
                <Box ref={dims.observe}>
                    {children}
                </Box>
                {
                    enabled && showMore &&
                    <Typography
                        variant={"body2"}
                        sx={{
                            mt: 1,
                            cursor: "pointer",
                            color: darkGrey,
                            bottom: "8px",
                        }}
                        onClick={() => setShowMore(false)}
                    >
                        Show less
                    </Typography>
                }
            </Box>

            {
                !showMore && enabled &&
                <Row
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"center"}
                    sx={{
                        width: "100%",
                        // height: "1px",
                        cursor: "pointer",
                        color: darkGrey,
                        position: "absolute",
                        bottom: "0px",
                        // pb: 1,
                        // bgcolor: "white"
                    }}
                    onClick={() => setShowMore(true)}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            width: "100%",
                            boxShadow: `0 0 8px 16px ${bgcolor}`,
                            // margin: "auto"
                        }}
                        onClick={() => setShowMore(true)}
                    />
                    <Typography
                        variant={"body2"}
                        sx={{
                            cursor: "pointer",
                            color: darkGrey,
                            zIndex: 2,
                        }}
                        onClick={() => setShowMore(true)}
                    >
                        Show more
                    </Typography>
                </Row>
            }
        </Box>
    )
}
