import {Alert, AlertTitle, Box, Fab, Typography} from "@mui/material";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Column} from "../common/motion_mui";
import {Channel, Member} from "../../slices/reduxTypes";
import {MarkdownRenderer} from "../markdownEditor/markdownRenderer";
import {useThreads} from "../../hooks/useThreads";
import {RootState} from "../../store";
import {selectAllChatThreads} from "../../slices/chatThreadSlice";
import {ChatThread} from "./chatThread";
import {ThreadForm} from "./threadForm";
import {discunaAppFrontendApiHost} from "../../../discunaAppToolkit/discunaAppFrontendCom/discunaAppFrontendApiHost";
import {
    discunaCoreFrontendApiClient
} from "../../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {getLicenseId} from "../../../discunaAppToolkit/utils/licenseUtils";
import {LICENSE_ID_MODERATOR} from "../../appEnv";
import CloseIcon from '@mui/icons-material/Close';
import {useUpdateSW} from "../../hooks/useUpdateSW";
import EditIcon from '@mui/icons-material/Edit';
import {EditChannelModal} from "./editChannelModal";



export function ChatChannelApp({member, channel, communityId}: {
    member: Member,
    channel: Channel,
    communityId: string,
}) {

    // fetch threads
    useThreads(member.id, communityId, channel.id)
    const threads = useSelector((state: RootState) => selectAllChatThreads(state))

    // sort threads
    const sortedThreads = useMemo(() => {
        const sortedThreads = [...threads]
        sortedThreads.sort((a, b) => a.createdOn > b.createdOn ? -1 : 1)
        return sortedThreads
    }, [threads])

    // callbacks
    const getMediaFolderUrl = useCallback(() => {
        return `communities/${communityId}/channels/${channel.id}`
    }, [channel.id, communityId])

    // check permissions
    const hasWriteThreadPermission = useMemo(() => {
        return (
            !channel.details.permissions.write ||
            channel.details.permissions.write.roles.length === 0 ||
            channel.details.permissions.write.roles.some((role) => member.details.roles.includes(role)) ||
            channel.details.permissions.write.userIds.includes(member.id)
        )
    }, [channel, member])


    // handle inbox message clicks
    const [highlight, setHighlight] = useState<null | string>(null)
    useEffect(() => {
        return discunaAppFrontendApiHost.onNotificationClick<{threadId: string, commentId?: string}>((args) => {
            console.log("highlight", args.data.commentId??args.data.threadId)
            setHighlight(args.data.commentId??args.data.threadId)
        })
    }, [channel.id])

    useEffect(() => {
        discunaCoreFrontendApiClient.pageIsReady()
    }, [])

    // const memberLicenseId = useMemo(() => {
    //     if (!member) return null
    //     return getLicenseId(member.details.licenses??[])
    // }, [member])
    // const hasModeratorPrivileges = useMemo(() => {
    //     if (!member) return false
    //     return ["administrator", "moderator"].some(role => member.details.roles.includes(role))
    // }, [member])
    // const showLicenseWarningInit = useMemo(() => {
    //     return hasModeratorPrivileges && memberLicenseId !== LICENSE_ID_MODERATOR
    // }, [hasModeratorPrivileges, memberLicenseId])
    const showLicenseWarningInit = false
    const [showLicenseWarning, setShowLicenseWarning] = useState(showLicenseWarningInit)

    // check for updates
    const updateSWSnackbar = useUpdateSW()
    const [showEditForm, setShowEditForm] = useState(false)

    return (
        <Box sx={{
            width: "100%",
            minHeight: "100%",
            maxHeight: "100%",
            overflow: "auto",
            bgcolor: "white",
            position: "relative",
        }}>
            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"center"}
                sx={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    pb: 4,
                }}
            >
                <Column
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"stretch"}
                    sx={{
                        maxWidth: "min(100%, 1000px)",
                        minWidth: "min(100%, 1000px)",
                        // overflow: "hidden"
                    }}
                >
                    {
                        showLicenseWarning &&
                        <Alert severity={"warning"} sx={{m: 2, mb: -2}} onClose={() => setShowLicenseWarning(false)}>
                            <AlertTitle>Missing License</AlertTitle>
                            You have moderator privileges but you are using a free license. To use moderator privileges please ask the community administrator to upgrade your license.
                        </Alert>
                    }
                    <Typography variant={"h1"} sx={{mx: 2, mt: 9, textAlign: "center"}}>
                        {channel.name}
                    </Typography>
                    <Box sx={{mx: 2, mt: 1, mb: 7, textAlign: "center"}}>
                        <MarkdownRenderer markdown={channel.details.description ?? ""}/>
                    </Box>
                    {
                        hasWriteThreadPermission &&
                        <ThreadForm channelId={channel.id} communityId={communityId} getMediaFolderUrl={getMediaFolderUrl}/>
                    }
                    {
                        sortedThreads.map((thread) => {
                            return (
                                <ChatThread initiallyCollapsed={true} highlight={highlight} setHighlight={setHighlight} sx={{mt: 2}} key={thread.id} communityId={communityId} channelId={channel.id} threadId={thread.id} getMediaFolderUrl={getMediaFolderUrl}/>
                            )
                        })
                    }
                </Column>
            </Column>
            {updateSWSnackbar}

            {
                member && (member.details.roles.includes("administrator") || member.details.roles.includes("moderator"))  &&
                <Fab
                    color={"primary"}
                    sx={{
                        position: "fixed",
                        right: 32,
                        bottom: 32,
                        transform: `scale(${showEditForm ? 0 : 1})`,
                        opacity: showEditForm ? 0 : 1,
                        transition: "300ms"
                    }}
                    onClick={() => setShowEditForm(true)}
                >
                    <EditIcon/>
                </Fab>
            }

            <EditChannelModal
                onClose={() => setShowEditForm(false)}
                show={showEditForm}
                communityId={communityId}
                channelId={channel.id}
            />
        </Box>
    )
}

