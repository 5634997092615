

export function updateArrayItemWithId<T extends {id: string}>(arr: T[], id: string, change: Partial<T>) {
    let i = 0
    let foundItem = false;
    for(;i < arr.length; ++i) {
        if (arr[i].id === id) {
            foundItem = true
            break;
        }
    }
    if(!foundItem) return arr
    const newItem = {
        ...arr[i],
        ...change
    }
    const res = [...arr]
    res.splice(i, 1, newItem)
    return res
}

export function getCompareVal<T>(a: T, b: T) {
    if (a === b) return 0
    if (a < b) return -1
    else return 1
}


export function arraySavePush<Type>(arr: Type[], ele: Type): Type[] {
    // TODO this method is log N times more expensive than just iterating over all values
    const s = new Set(arr)
    s.add(ele)
    return Array.from(s)
}

export function arraySaveDelete<Type>(arr: Type[], ele: Type): Type[] {
    // TODO this method is log N times more expensive than just iterating over all values
    const s = new Set(arr)
    s.delete(ele)
    return Array.from(s)
}

export function removeArrayItemWithId<T extends {id: string}>(arr: T[], id: string) {
    let i = 0
    let foundItem = false;
    for(;i < arr.length; ++i) {
        if (arr[i].id === id) {
            foundItem = true
            break;
        }
    }
    if(!foundItem) return arr
    const res = [...arr]
    res.splice(i, 1)
    return res
}