import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {RootState} from "../../store";
import {getIdToken} from "firebase/auth";

// TODO => first program backend
import {signIn, signOut} from "../../slices/authSlice";
import {firebaseAuth} from "../../../firebase/firebase";

export function useIsAuthor<T extends {authorId: string | null}>(data: T) {
    const auth = useSelector((state: RootState) => state.auth)
    if(auth.isSignedIn) {
        return auth.uid === data.authorId
    }
    return false
}

export async function refreshToken(){
    const currentUser = firebaseAuth.currentUser
    if(currentUser) await getIdToken(currentUser, true)
}

export function useAuth() {
    const dispatch = useDispatch()
    useEffect(() => {
        // check if user has upgraded
        const cancel = firebaseAuth.onIdTokenChanged(async (user) => {
            if(user) {
                dispatch(signIn({
                    uid: user.uid,
                    providerId: user.providerId,
                }))
            } else {
                dispatch(signOut());
            }
        })
        return () => {
            cancel()
        }
    }, [dispatch]);
}
